import { http } from '@/utils'
// import { useCraftsMasterStore } from '@/store/craftsMasterStore'
// import Chance from 'chance'
// import CraftsMaster, { TestDataLimit } from '@/common/craftsMaster'
// import { EmployersKey } from '@/common/employer'

// const chance = new Chance()

// const getTestEmployer = (user) => {
// 	const companyName = chance.company()
// 	let emailDomain = companyName.replace(/ /g, '').split(',')[0]
// 	emailDomain = emailDomain.split('').reverse()
// 	if (emailDomain[0] === '.') {
// 		emailDomain.shift()
// 	}
// 	emailDomain = emailDomain.reverse().join('')

// 	return {
// 		EmployerId: user ? user.CompanyId : chance.hash({ length: 10 }),
// 		Name: companyName,
// 		Address: chance.address(),
// 		City: chance.city(),
// 		State: chance.state(),
// 		Zip: chance.zip(),
// 		Notes: chance.sentence(),
// 		Vendors: [],
// 		_new: false
// 	}
// }

// const getTestEmployers = () => {
// 	return new Promise((resolve, reject) => {
// 		const date = new Date()

// 		const employers = []

// 		const store = useCraftsMasterStore()
// 		const employerCount = chance.integer({
// 			min: store.testDataLimits[TestDataLimit.Employers].range[0],
// 			max: store.testDataLimits[TestDataLimit.Employers].range[1]
// 		})
// 		for (let i = 0; i < employerCount; i++) {
// 			employers.push(getTestEmployer())
// 		}
// 		localStorage.setItem(EmployersKey, JSON.stringify(employers))

// 		console.log(`employers: ${employers.length} in ${new Date() - date} ms`)
// 		resolve({ data: employers })
// 		reject(new Error('EmployerDataService.getTestEmployers: error getting employers'))
// 	})
// }

class EmployerDataService {
	getAll () {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const date = new Date()
		// 		let employers = localStorage.getItem(EmployersKey)
		// 		if (employers) {
		// 			employers = JSON.parse(employers)
		// 		} else {
		// 			employers = []
		// 		}
		// 		console.log(`employers: ${employers.length} in ${new Date() - date} ms`)
		// 		resolve({ data: employers })
		// 		reject(new Error('EmployerDataService.getAll: error getting employers'))
		// 	})
		// } else {
		return http.get('/employers')
		// }
	}

	getById (id) {
		return http.get(`/employers/${id}`)
	}

	// async getTestEmployers () {
	// 	const testEmployers = await getTestEmployers()
	// 	return http.post('/employers/bulk', testEmployers.data)
	// }

	// getTestEmployer () {
	// 	return new Promise((resolve) => {
	// 		resolve({
	// 			data: getTestEmployer()
	// 		})
	// 	})
	// }

	create (data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const employer = Object.assign({}, data)
		// 		employer.EmployerId = chance.hash({ length: 5 })
		// 		employer._new = true
		// 		let employers = localStorage.getItem(EmployersKey)
		// 		if (employers) {
		// 			employers = JSON.parse(employers)
		// 			employers.push(employer)
		// 			localStorage.setItem(EmployersKey, JSON.stringify(employers))
		// 			resolve({ data: employer })
		// 		} else {
		// 			reject(new Error('EmployerDataService.create: no employers found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.post('/employers', data)
		// }
	}

	update (id, data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		let employers = localStorage.getItem(EmployersKey)
		// 		if (employers) {
		// 			employers = JSON.parse(employers)
		// 			const employer = employers.find(e => e.EmployerId === id)
		// 			if (employer) {
		// 				Object.assign(employer, data)
		// 				localStorage.setItem(EmployersKey, JSON.stringify(employers))
		// 				resolve({ data: employer })
		// 			} else {
		// 				reject(new Error(`EmployerDataService.update: employer ${id} not found in localStorage`))
		// 			}
		// 		} else {
		// 			reject(new Error('EmployerDataService.update: no employers found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.put(`/employers/${id}`, data)
		// }
	}

	delete (id) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		let employers = localStorage.getItem(EmployersKey)
		// 		if (employers) {
		// 			employers = JSON.parse(employers)
		// 			const employerIndex = employers.findIndex(e => e.EmployerId === id)
		// 			if (employerIndex !== -1) {
		// 				employers.splice(employerIndex, 1)
		// 				localStorage.setItem(EmployersKey, JSON.stringify(employers))
		// 				resolve({ data: id })
		// 			} else {
		// 				reject(new Error(`EmployerDataService.delete: employer ${id} not found in localStorage`))
		// 			}
		// 		} else {
		// 			reject(new Error('EmployerDataService.delete: no employers found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.delete(`/employers/${id}`)
		// }
	}

	deleteAll () {
		return http.delete('/employers')
	}

	// getByUser (user) {
	// 	if (CraftsMaster.DataSource === 'test') {
	// 		return new Promise((resolve, reject) => {
	// 			let employers = localStorage.getItem(EmployersKey)
	// 			let employer
	// 			if (employers) {
	// 				employers = JSON.parse(employers)
	// 				employer = employers.find(e => e.EmployerId === user.companyId)
	// 				if (!employer) {
	// 					employer = getTestEmployer(user)
	// 					employers.push(employer)
	// 					localStorage.setItem(EmployersKey, JSON.stringify(employers))
	// 				}
	// 			} else {
	// 				employer = getTestEmployer(user)
	// 				localStorage.setItem(EmployersKey, JSON.stringify([employer]))
	// 			}
	// 			resolve({ data: employer })
	// 			reject(new Error('EmployerDataService.getByUser: Unknown user'))
	// 		})
	// 	} else {
	// 		return http.get(`/employer?user=${user}`)
	// 	}
	// }
}

export default new EmployerDataService()
