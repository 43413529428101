import axios from 'axios'
import CraftsMaster from '@/models/craftsMaster'
import moment from 'moment'

let baseURL = 'https://craftsmaster.net/api'

if (CraftsMaster.DataSource === 'local') {
	baseURL = 'http://localhost:8080/api'
}

export const http = axios.create({
	baseURL,
	headers: {
		'Content-type': 'application/json'
	}
})

export const Direction = {
	Ascending: true,
	Descending: false
}

export function columnSort (a, b, sortConfig) {
	const field = sortConfig.field
	const direction = sortConfig.direction
	if (field) {
		const fieldName = sortConfig.fieldNames[field.id]
		const c = Object.assign({}, a)
		a = direction === Direction.Ascending ? a : b
		b = direction === Direction.Ascending ? b : c
		if (field.type === 'number') {
			return a[fieldName] - b[fieldName]
		} else if (field.items) {
			if (typeof a[fieldName] === 'string') {
				const itemA = field.items.find(i => i.value === a[fieldName])
				const itemB = field.items.find(i => i.value === b[fieldName])
				if (itemA) {
					return ('' + itemA.text).localeCompare(itemB.text)
				} else {
					return ('' + a[fieldName]).localeCompare(b[fieldName])
				}
			} else {
				return ('' + field.items[a[fieldName]].text).localeCompare(field.items[b[fieldName]].text)
			}
			// } else if (field.radioItems) {
			// 	return ('' + field.items[a[index]][field.itemsSortProperty]).localeCompare(field.items[b[index]][field.itemsSortProperty])
		} else {
			return ('' + a[fieldName]).localeCompare(b[fieldName])
		}
	} else {
		return 0
	}
}

export function formatDateMediumUtc (date) {
	return moment(date).utc().format('MMM D, YYYY')
}

export function formatDateMedium (date) {
	return moment(date).format('MMM D, YYYY')
}
export function formatTime (date) {
	return moment(date).format('h:mma')
}

export function numericKeyDown (event) {
	if (isNaN(event.key) && event.key !== 'Backspace' && event.key.substr(0, 5) !== 'Arrow' && event.key !== 'Delete') {
		event.preventDefault()
	}
}

export function wordSplit (title) {
	const words = []
	if (title) {
		const text = title.split(' ')
		text.forEach(t => {
			words.push({
				firstLetter: t[0],
				minusFirstLetter: t.slice(1)
			})
		})
	}
	return words
}

export function formatCurrencyToString (currency) {
	if (!isNaN(currency)) {
		return currency > 0 ? `$ ${parseFloat(currency).toFixed(2).toString()}` : '--'
	} else {
		return '--'
	}
}

export function stateNameToCode (name) {
	const states = {
		arizona: 'AZ',
		alabama: 'AL',
		alaska: 'AK',
		arkansas: 'AR',
		california: 'CA',
		colorado: 'CO',
		connecticut: 'CT',
		'district of columbia': 'DC',
		delaware: 'DE',
		florida: 'FL',
		georgia: 'GA',
		hawaii: 'HI',
		idaho: 'ID',
		illinois: 'IL',
		indiana: 'IN',
		iowa: 'IA',
		kansas: 'KS',
		kentucky: 'KY',
		louisiana: 'LA',
		maine: 'ME',
		maryland: 'MD',
		massachusetts: 'MA',
		michigan: 'MI',
		minnesota: 'MN',
		mississippi: 'MS',
		missouri: 'MO',
		montana: 'MT',
		nebraska: 'NE',
		nevada: 'NV',
		'new hampshire': 'NH',
		'new jersey': 'NJ',
		'new mexico': 'NM',
		'new york': 'NY',
		'north carolina': 'NC',
		'north dakota': 'ND',
		ohio: 'OH',
		oklahoma: 'OK',
		oregon: 'OR',
		pennsylvania: 'PA',
		'rhode island': 'RI',
		'south carolina': 'SC',
		'south dakota': 'SD',
		tennessee: 'TN',
		texas: 'TX',
		utah: 'UT',
		vermont: 'VT',
		virginia: 'VA',
		washington: 'WA',
		'west virginia': 'WV',
		wisconsin: 'WI',
		wyoming: 'WY',
		'american samoa': 'AS',
		guam: 'GU',
		'northern mariana islands': 'MP',
		'puerto rico': 'PR',
		'us virgin islands': 'VI',
		'us minor outlying islands': 'UM'
	}

	const a = name.trim().replace(/[^\w ]/g, '').toLowerCase() // trim, remove all non-word characters with the exception of spaces, and convert to lowercase
	if (states[a] !== null) {
		return states[a]
	}

	return name
}
