import { defineStore } from 'pinia'
import Chance from 'chance'
// import { nextTick } from 'vue'
// import VueScrollTo from 'vue-scrollto'
import VendorDataService from '@/services/VendorDataService'
import EmployerDataService from '@/services/EmployerDataService'
import { useCraftsMasterStore } from './craftsMasterStore'
import { useEmployerStore } from './employerStore'
// import { VendorsKey } from '@/common/vendor'

const chance = Chance()

// const saveTimeout = 1000

export const useVendorStore = defineStore('vendor', {
	state: () => ({
		vendors: [],
		currentVendorId: null,
		testVendor: null
	}),
	getters: {
		vendorById: state => id => {
			const vendor = state.vendors.find(v => v.VendorId === id)
			return vendor ? { ...vendor } : null
		},
		currentVendor: state => state.vendors.find(v => v.VendorId === state.currentVendorId),
		currentVendors: state => state.vendors,
		vendorList: state => state.vendors
			.map(v => ({
				text: v.Name,
				value: v.VendorId
			}))
			.sort((a, b) => a.text.localeCompare(b.text))
	},
	actions: {
		clearVendors () {
			this.vendors = []
		},
		async getVendors () {
			try {
				const response = await VendorDataService.getAll()
				if (response) {
					this.vendors = response.data
				}
			} catch (error) {
				this.vendors = []

				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.message,
					type: 'error'
				})
			}
		},
		async getVendorById (id) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await VendorDataService.getById(id)
				if (response && response.data) {
					const index = this.vendors.findIndex(v => v.VendorId === response.data.VendorId)
					if (index === -1) {
						this.vendors.push(response.data)
					}
					this.currentVendorId = response.data.VendorId
				} else {
					this.currentVendorId = null
				}
			} catch (error) {
				this.currentVendorId = null
			} finally {
				craftsMasterStore.loading = false
			}
		},
		// async getTestVendors () {
		// 	localStorage.removeItem(VendorsKey)
		// 	VendorDataService.getTestVendors().then(response => {
		// 		if (response) {
		// 			this.vendors = response.data
		// 		}
		// 	}, error => {
		// 		this.vendors = []

		// 		const craftsMasterStore = useCraftsMasterStore()
		// 		craftsMasterStore.setSnackbar({
		// 			value: true,
		// 			text: error.message,
		// 			type: 'error'
		// 		})
		// 	})
		// },
		// } else {
		// 	// test code
		// 	const timeout = new Promise((resolve) => {
		// 		window.setTimeout(() => {
		// 			vendor.VendorId = chance.hash({ length: 10 })
		// 			const existingVendor = this.vendors.find(v => v.VendorId === vendor.VendorId)
		// 			if (!existingVendor) {
		// 				this.vendors.push(vendor)
		// 			}
		// 			this.currentVendorId = vendor.VendorId

		// 			craftsMasterStore.loading = false
		// 			craftsMasterStore.setSnackbar({
		// 				value: true,
		// 				text: 'Vendor saved.'
		// 			})
		// 			nextTick(() => {
		// 				VueScrollTo.scrollTo(`#vendor-${vendor.VendorId}`, 100, {
		// 					container: '#vendor-container',
		// 					onDone () {
		// 						const index = this.vendors.findIndex(v => v.VendorId === vendor.VendorId)

		// 						if (index !== -1) {
		// 							this.vendors[index]._new = true
		// 						}
		// 					}
		// 				})
		// 			})
		// 			resolve()
		// 		}, saveTimeout)
		// 	})

		// 	await timeout
		// }
		saveVendor (vendor, options = {}) {
			if (vendor.hasOwnProperty('VendorId') && vendor.VendorId) {
				this.updateVendor(vendor)
			} else {
				this.addVendor(vendor, options)
			}
		},
		async addVendor (vendor, options = {}) {
			const craftsMasterStore = useCraftsMasterStore()
			if (!options.silentMode) {
				craftsMasterStore.loading = true
			}

			try {
				vendor.VendorId = chance.hash({ length: 10 })
				vendor.VendorCode = vendor.VendorCode || vendor.Name.slice(0, 3).trim().toUpperCase()
				// skip if a vendor exists with the same name
				const vendorExists = this.vendors.findIndex(v => {
					return (v.Name === vendor.Name ||
						v.VendorId === vendor.VendorId ||
						v.VendorCode === vendor.VendorCode) !== -1
				})
				if (vendorExists) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Vendor (${vendor.Name}) not added: duplicate name, id, or code`
					})
					return
				}
				const response = await VendorDataService.create(vendor)
				if (response && response.data) {
					this.vendors.push(response.data)

					if (!options.silentMode) {
						craftsMasterStore.setSnackbar({
							value: true,
							text: `Vendor (${response.data.Name}) added`
						})
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Vendor (${vendor.Name} | ${vendor.VendorId}) not added`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				if (!options.silentMode) {
					craftsMasterStore.loading = false
				}
			}
		},
		async updateVendor (vendor) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await VendorDataService.update(vendor.VendorId, vendor)
				if (response && response.data) {
					const updatedVendor = this.vendors.find(v => v.VendorId === response.data.VendorId)
					if (updatedVendor) {
						Object.assign(updatedVendor, response.data)
					}

					craftsMasterStore.setSnackbar({
						value: true,
						text: `Vendor (${response.data.Name}) updated`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Vendor (${vendor.Name} | ${vendor.VendorId}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async deleteVendor (vendor) {
			const craftsMasterStore = useCraftsMasterStore()

			if (vendor.VendorId) {
				craftsMasterStore.loading = true

				try {
					const response = await VendorDataService.delete(vendor.VendorId)
					if (response) {
						const deletedVendorIndex = this.vendors.findIndex(v => v.VendorId === response.data)
						if (deletedVendorIndex !== -1) {
							this.vendors.splice(deletedVendorIndex, 1)
						}

						const employerStore = useEmployerStore()
						const employers = employerStore.employers

						for (const i in employers) {
							const e = employers[i]
							const index = e.Vendors.findIndex(v => v.VendorId === vendor.VendorId)
							if (index !== -1) {
								e.Vendors.splice(index, 1)
								await EmployerDataService.update(e.EmployerId, e)
							}
						}

						craftsMasterStore.setSnackbar({
							value: true,
							text: `Vendor (${vendor.Name}) deleted`
						})
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `Vendor (${vendor.Name} | ${vendor.VendorId}) not deleted`
						})
					}
				} catch (error) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: error.response.data.message
					})
				} finally {
					craftsMasterStore.loading = false
				}
			} else {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: `Vendor (${vendor.Name}) not deleted: no VendorId`
				})
			}
		},
		async deleteAllVendors () {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				await VendorDataService.deleteAll()
				this.clearVendors()
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		setCurrentVendorId (id) {
			this.currentVendorId = id
		}
		// getTestVendor (vendorId) {
		// 	VendorDataService.getTestVendor(vendorId).then(response => {
		// 		if (response) {
		// 			this.testVendor = response.data
		// 		}
		// 	})
		// }
	}
})
