import moment from 'moment'
import { useUserStore } from '@/store/userStore'

export const Type = {
	Interview: 0,
	Testing: 1, // technical interview
	Onboarding: 2,
	Assignment: 3,
	Other: 4,
	JobOrder: 5
}

export const Types = [
	{
		value: Type.Interview,
		text: 'Interview',
		color: 'blue',
		icon: 'mdi-account-tie',
		code: 'INT'
	}, {
		value: Type.Testing,
		text: 'Testing',
		color: 'lime',
		icon: 'mdi-account-question',
		code: 'TES'
	}, {
		value: Type.Onboarding,
		text: 'Onboarding',
		color: 'red',
		icon: 'mdi-account-wrench',
		code: 'ONB'
	}, {
		value: Type.Assignment,
		text: 'On Assignment',
		color: 'green',
		icon: 'mdi-account-hard-hat',
		code: 'ONA'
	}, {
		value: Type.Other,
		text: 'Other',
		color: 'orange',
		icon: 'mdi-account-plus',
		code: 'OTH'
	}, {
		value: Type.JobOrder,
		text: 'Job Order',
		color: 'grey',
		icon: 'mdi-clipboard-multiple-outline',
		code: 'JOB'
	}
]
export const Status = {
	Pending: 0,
	Scheduled: 1
}

export const Statuses = [
	{
		value: Status.Pending,
		text: 'Pending',
		color: 'indigo',
		icon: 'mdi-calendar-question'
	}, {
		value: Status.Scheduled,
		text: 'Scheduled',
		color: 'green',
		icon: 'mdi-calendar-check'
	}
]

const Field = {
	CraftsmanId: 0,
	TypeId: 1,
	StatusId: 2,
	Location: 3,
	JobOrderId: 4,
	Start: 5,
	End: 6,
	EventId: 7
}

const Fields = [{
	id: Field.CraftsmanId,
	label: 'Craftsman',
	value: null,
	groupable: true,
	sortable: true
}, {
	id: Field.TypeId,
	label: 'Type',
	value: null,
	groupable: true,
	sortable: true
}, {
	id: Field.StatusId,
	label: 'Status',
	value: null,
	groupable: true,
	sortable: true
}, {
	id: Field.Location,
	label: 'Location',
	sortable: true,
	value: null
}, {
	id: Field.JobOrderId,
	label: 'Job Order',
	value: null,
	groupable: true,
	sortable: true
}, {
	id: Field.Start
}, {
	id: Field.End
}, {
	id: Field.EventId
}]

const FieldNames = Object.keys(Field)

const isInterview = (event) => event.TypeId === Type.Interview

const isPast = function (event) {
	const endDate = moment(event.End)
	return endDate.isBefore()
}

const isNew = (event) => !event.EventId && !isPast(event)

const isCreator = function (event) {
	const userStore = useUserStore()

	return event.CreatorId === userStore.currentUser.CompanyId || userStore.isAdmin
}

const canBeRemoved = (event) => {
	const userStore = useUserStore()

	return !isNew(event) && !isPast(event) && (isInterview(event) || userStore.isVendor)
}

const canBeRequested = (event) => isNew(event) && event.TypeId === Type.Interview

const canBeEdited = (event) => {
	return (isNew(event) || event.StatusId === Status.Pending) && !isPast(event) && isCreator(event)
}

const canBeScheduled = (event) => {
	return (
		(isNew(event) && event.TypeId !== Type.Interview) ||
		(!isCreator(event) && event.StatusId === Status.Pending && event.TypeId === Type.Interview)
	) && !isPast(event)
}

export default {
	Icon: 'mdi-calendar',
	isNew,
	isPast,
	canBeRemoved,
	canBeRequested,
	canBeEdited,
	canBeScheduled,
	Type,
	Types,
	Status,
	Statuses,
	Field,
	Fields,
	FieldNames
}
