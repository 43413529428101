import { defineStore } from 'pinia'
import Craftsman from '@/models/craftsman'
import JobOrder from '@/models/jobOrder'
import User from '@/models/user'
import CraftsmanDataService from '@/services/CraftsmanDataService'
import { useCraftsMasterStore } from './craftsMasterStore'
import { useJobOrderStore } from './jobOrderStore'
import { useVendorStore } from './vendorStore'
import { useEmployerStore } from './employerStore'
import { useUserStore } from './userStore'
import { Portals, Portal, View } from '@/models/craftsMaster'
import Vue from 'vue'
import Chance from 'chance'
import moment from 'moment'

const chance = Chance()
const hashLength = 4

// const postTimeout = 1500

export const useCraftsmanStore = defineStore('craftsman', {
	state: () => ({
		craftsmen: [],
		currentCraftsmanId: null,
		testCraftsman: null,
		selectedCraftsman: null,
		showInactiveJobOrders: false,
		showArchivedCraftsmen: false
	}),
	getters: {
		currentCraftsman: state => state.craftsmen.find(c => c.CraftsmanId === state.currentCraftsmanId),
		craftsmanById: state => id => {
			const craftsman = state.craftsmen.find(c => c.CraftsmanId === id)
			return craftsman ? { ...craftsman } : null
		},
		adminCraftsmen (state) {
			const adminCraftsmen = []
			if (state.craftsmen.length) {
				const vendorStore = useVendorStore()
				const jobOrderStore = useJobOrderStore()
				state.craftsmen.forEach(c => {
					let Vendor = vendorStore.vendors.find(v => v.VendorId === c.VendorId)
					Vendor = Vendor ? Vendor.Name : ''
					const jobOrderCount = jobOrderStore.jobOrders.filter(j => {
						return Array.isArray(j.Craftsmen) && j.Craftsmen.some(joc => joc.CraftsmanId === c.CraftsmanId)
					}).length
					adminCraftsmen.push({
						...c,
						AddressInfo: `${c.Address}<br />${c.City}, ${c.State} ${c.Zip}`,
						ContactInfo: `${c.Phone}<br />${c.Email}`,
						Rate: c.Rate && !isNaN(c.Rate) ? parseFloat(c.Rate).toFixed(2) : 0,
						Vendor,
						Craft: JobOrder.Crafts[c.CraftId].text,
						Class: JobOrder.Classes[c.ClassId].altText,
						jobOrderCount
					})
				})
			}
			return adminCraftsmen
		},
		employerCraftsmen () {
			const employerId = useEmployerStore().currentEmployerId
			const jobOrderStore = useJobOrderStore()
			const craftsmenJobOrders = jobOrderStore.jobOrders
				.filter(j =>
					j.EmployerId === employerId &&
					!j.Archived &&
					Array.isArray(j.Craftsmen) && j.Craftsmen.length > 0)
			const employerCraftsmen = []

			// snag all active job order data for each craftsman
			craftsmenJobOrders.forEach(jobOrder => {
				jobOrderStore.jobOrderCraftsmen(jobOrder)
					.filter(joc => joc.StatusId !== Craftsman.Status.Available)
					.forEach(joc => {
						const jobOrderDetails = {
							JobOrderId: joc.JobOrderId,
							isInactive: moment(jobOrder.EndDate).isBefore(),
							updatedDate: moment(joc.updatedAt).format('MMM D, YYYY'),
							updatedTime: moment(joc.updatedAt).format('h:mm a'),
							updatedAt: joc.updatedAt,
							Name: JobOrder.getJobTitle(jobOrder).yardJobOrderIdTwoLine,
							jobOrderNameAlt: JobOrder.getJobTitle(jobOrder).craftClassJobOrderId,
							routeIcon: JobOrder.IconNavigate,
							route: {
								name: Portals[Portal.Employer].tabs[View.EmployerJobOrderCraftsmen].name,
								params: {
									companyId: employerId,
									jobOrderId: joc.JobOrderId
								}
							}
						}
						const ec = employerCraftsmen.find(c => c.CraftsmanId === joc.CraftsmanId)
						if (ec) {
							ec.activeJobOrders.push(jobOrderDetails)
							ec.activeJobOrders.sort((a, b) => ('' + b.updatedAt).localeCompare(a.updatedAt))
						} else {
							const jobOrderCount = jobOrderStore.jobOrders.filter(j => {
								return Array.isArray(j.Craftsmen) && j.Craftsmen.some(c => c.CraftsmanId === joc.CraftsmanId)
							}).length
							employerCraftsmen.push({
								...joc,
								jobOrderCount,
								activeJobOrders: [jobOrderDetails]
							})
						}
					})
			})

			return employerCraftsmen
		},
		inactiveEmployerJobOrderCraftsmen () {
			return this.employerCraftsmen.filter(c => c.isInactive)
		},
		vendorCraftsmen: state => {
			const vendorId = useVendorStore().currentVendorId
			const jobOrderStore = useJobOrderStore()

			let vendorCraftsmen = []
			vendorCraftsmen = state.craftsmen
				.filter(c =>
					c.VendorId === vendorId &&
					((c.Archived && state.showArchivedCraftsmen) ||
						(!c.Archived && !state.showArchivedCraftsmen)))
				.map(c => {
					const jobOrderCount = jobOrderStore.jobOrders.filter(j => {
						return Array.isArray(j.Craftsmen) && j.Craftsmen.some(joc => joc.CraftsmanId === c.CraftsmanId)
					}).length
					return {
						...c,
						Craft: JobOrder.Crafts[c.CraftId].text,
						Class: JobOrder.Classes[c.ClassId].altText,
						Rate: c.Rate && !isNaN(c.Rate) ? parseFloat(c.Rate).toFixed(2) : 0,
						jobOrderCount,
						activeJobOrders: [],
						contactInfo: {
							craftsmanId: c.CraftsmanId,
							name: c.Name,
							email: c.Email,
							phone: c.Phone,
							address: c.Address,
							city: c.City,
							state: c.State,
							zip: c.Zip
						}
					}
				})

			// snag all active job order data for each craftsman
			jobOrderStore.vendorJobOrders
				.filter(j => Array.isArray(j.Craftsmen) && j.Craftsmen.length && moment(j.EndDate).isSameOrAfter())
				.forEach(j => {
					j.Craftsmen
						.filter(joc => joc.VendorId === vendorId)
						.forEach(joc => {
							const vc = vendorCraftsmen.find(c => c.CraftsmanId === joc.CraftsmanId)
							if (vc) {
								vc.activeJobOrders.push({
									JobOrderId: j.JobOrderId,
									Name: JobOrder.getJobTitle(j).nameTwoLine,
									StatusId: joc.StatusId,
									Status: Craftsman.Statuses[joc.StatusId].text,
									route: {
										name: Portals[Portal.Vendor].tabs[View.VendorJobOrderCraftsmen].name,
										params: {
											companyId: vendorId,
											jobOrderId: j.JobOrderId
										}
									},
									routeIcon: JobOrder.IconNavigate
								})
								vc.activeJobOrders.sort((a, b) => a.Name.localeCompare(b.Name))
							}
						})
				})

			return vendorCraftsmen
		},
		archivedCraftsmen: state => {
			const vendorId = useVendorStore().currentVendorId

			const archivedCraftsmen = state.craftsmen.filter(c => c.VendorId === vendorId && c.Archived)

			return archivedCraftsmen
		},

		/***********************/
		/**    older codes    **/
		/***********************/

		availableVendorCraftsmen () {
			const jobOrderStore = useJobOrderStore()
			const jobOrder = jobOrderStore.currentJobOrder
			let craftsmen = []
			if (jobOrder) {
				craftsmen = this.vendorCraftsmen.filter(c => {
					return Craftsman.isAvailable(c.StatusId) &&
						c.ClassId === jobOrder.ClassId &&
						c.CraftId === jobOrder.CraftId &&
						!jobOrder.Craftsmen.find(joc => c.CraftsmanId === joc.CraftsmanId)
				})
			}
			return craftsmen
		},
		selectedAvailableVendorCraftsmen () {
			return this.availableVendorCraftsmen.filter(c => c._selected)
		}
	},
	actions: {
		clearCraftsmen (ids) {
			const craftsmen = []
			if (ids) {
				this.craftsmen.forEach(c => {
					if (!ids.includes(c.CraftsmanId)) {
						craftsmen.push(c)
					}
				})
			}
			this.craftsmen = craftsmen
		},
		async getCraftsmen () {
			try {
				const response = await CraftsmanDataService.getAll()
				if (response) {
					this.craftsmen = response.data
				}
			} catch (error) {
				this.craftsmen = []

				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.message,
					type: 'error'
				})
			}
		},
		async addCraftsmen (craftsmen) {
			const craftsMasterStore = useCraftsMasterStore()
			try {
				const vendorStore = useVendorStore()
				craftsmen.forEach(c => {
					const hash = chance.hash({ length: hashLength, casing: 'upper' })
					const vendor = vendorStore.vendors.find(v => v.VendorId === c.VendorId)
					c.CraftsmanId = `${JobOrder.Crafts[c.CraftId].code}${JobOrder.Classes[c.ClassId].code}-${vendor.VendorCode}-${hash}`
				})
				const response = await CraftsmanDataService.createCraftsmen(craftsmen)

				if (response) {
					craftsmen = response.data
					this.craftsmen = this.craftsmen.concat(craftsmen)

					craftsMasterStore.setSnackbar({
						value: true,
						text: `${craftsmen.length} craftsmen added.`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `${craftsmen.length}) not added`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			}
		},
		async saveCraftsman (craftsman, options = {}) {
			if (!craftsman.VendorId) {
				const vendorStore = useVendorStore()
				if (vendorStore.currentVendorId) {
					craftsman.VendorId = vendorStore.currentVendorId
				} else {
					const craftsMasterStore = useCraftsMasterStore()
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Craftsman (${craftsman.Name}) not added -- no VendorId`
					})
					return
				}
			}

			if (craftsman.hasOwnProperty('CraftsmanId') && craftsman.CraftsmanId) {
				await this.updateCraftsman(craftsman)
			} else {
				await this.addCraftsman(craftsman, options)
			}
		},
		async addCraftsman (craftsman, options = {}) {
			const craftsMasterStore = useCraftsMasterStore()
			if (!options.silentMode) {
				craftsMasterStore.loading = true
			}

			try {
				const resume = craftsman.ResumeFormData
				delete craftsman.ResumeFormData

				const hash = chance.hash({ length: hashLength, casing: 'upper' })
				const vendor = useVendorStore().vendors.find(v => v.VendorId === craftsman.VendorId)
				craftsman.CraftsmanId = `${JobOrder.Crafts[craftsman.CraftId].code}${JobOrder.Classes[craftsman.ClassId].code}-${vendor.VendorCode}-${hash}`

				const response = await CraftsmanDataService.create(craftsman)

				if (response) {
					craftsman = response.data
					this.craftsmen.push(craftsman)
					this.setCurrentCraftsmanId(craftsman.CraftsmanId)

					if (resume) {
						this.uploadResume(craftsman.CraftsmanId, resume)
						craftsman.ResumeFormData = resume
					}

					if (!options.silentMode) {
						craftsMasterStore.setSnackbar({
							value: true,
							text: `Craftsman (${craftsman.Name}) added.`
						})
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Craftsman (${craftsman.Name} | ${craftsman.CraftsmanId}) not added`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			} finally {
				if (!options.silentMode) {
					craftsMasterStore.loading = false
				}
			}
		},
		async updateCraftsman (craftsman) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			const resume = craftsman.ResumeFormData
			this.uploadResume(craftsman.CraftsmanId, resume)
			delete craftsman.ResumeFormData // don't send resume...already uploaded above

			try {
				const response = await CraftsmanDataService.update(craftsman.CraftsmanId, craftsman)

				if (response && response.data) {
					const index = this.craftsmen.findIndex(c => c.CraftsmanId === craftsman.CraftsmanId)

					if (index !== -1) {
						Vue.set(this.craftsmen, index, response.data)

						craftsMasterStore.setSnackbar({
							value: true,
							text: `Craftsman (${craftsman.CraftsmanId}) updated.`
						})
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `Craftsman (${craftsman.CraftsmanId}) not updated: not found`
						})
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Craftsman (${craftsman.CraftsmanId}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async uploadResume (craftsmanId, resume) {
			const craftsMasterStore = useCraftsMasterStore()

			if (resume && craftsMasterStore.fileUploadInProgress) {
				try {
					const response = await CraftsmanDataService.upload(craftsmanId, resume)

					if (!response) {
						craftsMasterStore.loading = false
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `Craftsman (${craftsmanId}) resume not uploaded`
						})
					}
				} catch (error) {
					craftsMasterStore.loading = false
					craftsMasterStore.setSnackbar({
						value: true,
						text: error?.response?.data ? error.response.data.message : error.message,
						type: 'error'
					})
				} finally {
					craftsMasterStore.setFileUploadInProgress(false)
				}
			}
		},
		async setCurrentCraftsmanId (id = null) {
			this.currentCraftsmanId = id
		},
		async deleteCraftsman (craftsman) {
			const craftsMasterStore = useCraftsMasterStore()

			if (craftsman.CraftsmanId) {
				craftsMasterStore.loading = true

				try {
					const response = await CraftsmanDataService.delete(craftsman.CraftsmanId)
					if (response) {
						const index = this.craftsmen.findIndex(c => c.CraftsmanId === response.data)
						if (index !== -1) {
							this.craftsmen.splice(index, 1)
						}

						const jobOrderStore = useJobOrderStore()
						const jobOrderId = jobOrderStore.currentJobOrderId
						if (jobOrderId) {
							await jobOrderStore.updateJobOrderCraftsmen({
								showLoadingSpinner: false,
								statusId: null,
								craftsmanIds: [craftsman.CraftsmanId],
								vendorId: null,
								jobOrderId
							})
						}

						craftsMasterStore.setSnackbar({
							value: true,
							text: `Craftsman (${craftsman.Name}) deleted`
						})
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `Craftsman (${craftsman.Name} | ${craftsman.CraftsmanId}) not deleted`
						})
					}
				} catch (error) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: error?.response?.data ? error.response.data.message : error.message
					})
				} finally {
					craftsMasterStore.loading = false
				}
			} else {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: `Craftsman (${craftsman.Name}) not deleted: no CraftsmanId`
				})
			}
		},
		async getCraftsmenByUser (user) {
			const userStore = useUserStore()
			user = user || userStore.currentUser

			switch (user.TypeId) {
				case User.UserType.Vendor:
					await this.getVendorCraftsmen(user.CompanyId)
					break
				case User.UserType.Employer:
					await this.getEmployerCraftsmen(user.CompanyId)
					break
				case User.UserType.Admin:
					await this.getCraftsmen()
					break
			}
		},
		async getVendorCraftsmen (id) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			const vendorStore = useVendorStore()
			id = id || vendorStore.currentVendorId

			this.craftsmen = []

			try {
				const response = await CraftsmanDataService.getByVendorId(id)
				if (response) {
					this.craftsmen = response.data
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async getEmployerCraftsmen (id) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			const employerStore = useEmployerStore()
			id = id || employerStore.currentEmployerId

			this.craftsmen = []

			try {
				const response = await CraftsmanDataService.getByEmployerId(id)
				if (response) {
					this.craftsmen = response.data
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		selectCraftsmen (payload) {
			if (payload === undefined) {
				this.craftsmen.forEach(c => {
					Vue.set(c, '_selected', false)
				})
			} else {
				const craftsmen = this.craftsmen.filter(c => payload.ids.includes(c.CraftsmanId))

				if (craftsmen.length) {
					craftsmen.forEach(c => {
						Vue.set(c, '_selected', payload.selected)
					})
				}
			}
		},
		setSelectedCraftsman (craftsman) {
			this.selectedCraftsman = craftsman
		},
		updateCraftsmanExpanded (payload = {}) {
			this.craftsmen.forEach(c => {
				Vue.set(c, 'expanded', false)
			})
			if (payload.expanded && payload.hasOwnProperty('craftsmanId')) {
				const craftsman = this.craftsmen.find(c => c.CraftsmanId === payload.craftsmanId)
				if (craftsman) {
					Vue.set(craftsman, 'expanded', true)
				}
			}
		},
		setShowInactiveJobOrders () {
			this.showInactiveJobOrders = !this.showInactiveJobOrders
		},
		setShowArchivedCraftsmen () {
			this.showArchivedCraftsmen = !this.showArchivedCraftsmen
		},
		async unarchiveCraftsmen (ids) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await CraftsmanDataService.unarchive(ids)

				if (response && response.data && response.data.modifiedCount === ids.length) {
					const craftsmen = this.craftsmen.filter(c => ids.includes(c.CraftsmanId))
					for (const craftsman of craftsmen) {
						Vue.set(craftsman, 'Archived', false)
					}
					craftsMasterStore.setSnackbar({
						value: true,
						text: 'Craftsmen unarchived successfully'
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: 'Craftsmen not unarchived'
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async archiveCraftsmen (ids) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await CraftsmanDataService.archive(ids)

				if (response?.data?.modifiedCount === ids.length) {
					const craftsmen = this.craftsmen.filter(c => ids.includes(c.CraftsmanId))
					for (const craftsman of craftsmen) {
						Vue.set(craftsman, 'Archived', true)
					}
					craftsMasterStore.setSnackbar({
						value: true,
						text: 'Craftsmen archived successfully'
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: 'Craftsmen not archived'
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error?.response?.data ? error.response.data.message : error.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async updateCraftsmanIds (router) {
			const vendorStore = useVendorStore()
			const ids = this.craftsmen.map(c => {
				const vendor = vendorStore.vendors.find(v => v.VendorId === c.VendorId)
				const hash = chance.hash({ length: hashLength, casing: 'upper' })
				return {
					old: c.CraftsmanId,
					new: `${JobOrder.Crafts[c.CraftId].code}${JobOrder.Classes[c.ClassId].code}-${vendor.VendorCode}-${hash}`
				}
			})
			if (ids) {
				const response = await CraftsmanDataService.updateCraftsmanIds(ids)

				if (response?.status === 200) {
					router.go(0)
				}
			}
		},
		// N.B. not updating job order craftsmen - only send non-jocs
		async deleteCraftsmen (craftsmanIds) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await CraftsmanDataService.deleteCraftsmen(craftsmanIds)

				if (response && response.data) {
					this.clearCraftsmen(craftsmanIds)

					// const jobOrderStore = useJobOrderStore()
					// const jobOrderId = jobOrderStore.currentJobOrderId

					// if (jobOrderId) {
					// 	if (response.data.deletedCount === 0 || craftsmanIds === undefined) {
					// 		await jobOrderStore.updateJobOrderCraftsmen({
					// 			showLoadingSpinner: false,
					// 			statusId: null,
					// 			craftsmanIds: null,
					// 			vendorId: null,
					// 			jobOrderId
					// 		})
					// 	} else {
					// 		await jobOrderStore.updateJobOrderCraftsmen({
					// 			showLoadingSpinner: false,
					// 			statusId: null,
					// 			craftsmanIds,
					// 			vendorId: null,
					// 			jobOrderId
					// 		})
					// 	}
					// }

					craftsMasterStore.setSnackbar({
						value: true,
						text: `${response.data.deletedCount} Craftsmen deleted.`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.response.data.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},

		// async getTestCraftsman (vendorId) {
		// 	const vendorStore = useVendorStore()
		// 	vendorId = vendorId || vendorStore.currentVendorId

		// 	const response = await CraftsmanDataService.getTestCraftsman(vendorId)
		// 	if (response) {
		// 		this.testCraftsman = { ...response.data }
		// 	}
		// },
		// async getTestCraftsmen (payload) {
		// 	const vendorStore = useVendorStore()
		// 	payload = payload || {
		// 		vendorId: vendorStore.currentVendorId,
		// 		jobOrderId: null
		// 	}

		// 	try {
		// 		const response = await CraftsmanDataService.getTestCraftsmenByVendorId(payload.vendorId)

		// 		if (response) {
		// 			this.craftsmen = this.craftsmen.concat(response.data)

		// 			const craftsMasterStore = useCraftsMasterStore()
		// 			craftsMasterStore.setSnackbar({
		// 				value: true,
		// 				text: `${this.craftsmen.length} Craftsman added.`
		// 			})
		// 		}
		// 	} catch (error) {
		// 		this.craftsmen = []

		// 		const craftsMasterStore = useCraftsMasterStore()
		// 		craftsMasterStore.setSnackbar({
		// 			value: true,
		// 			text: error.message,
		// 			type: 'error'
		// 		})
		// 	}
		// },
		// call update Job Order Craftsmen directly
		// async updateCraftsmanStatus (payload) {
		// 	const jobOrderStore = useJobOrderStore()
		// 	payload.jobOrderId = payload.jobOrderId || jobOrderStore.currentJobOrderId
		// 	await jobOrderStore.updateJobOrderCraftsmen(payload)
		// },

		/*********************************/
		/**    older or unused codes    **/
		/*********************************/

		updateCraftsmanStatus_StoreOnly (payload) {
			const craftsman = this.craftsmen.find(c => c.CraftsmanId === payload.craftsmanId)
			if (craftsman) {
				craftsman.StatusId = payload.status
			}
		},
		selectAllAvailableVendorCraftsmen () {
			const selected = this.selectedAvailableVendorCraftsmen.length === 0
			this.availableVendorCraftsmen.forEach(function (c) {
				this.selectCraftsman({
					id: c.CraftsmanId,
					selected
				})
			})
		}
	}
})
