import { defineStore } from 'pinia'
import User from '@/models/user'
import UserDataService from '@/services/UserDataService'
import { useCraftsMasterStore } from './craftsMasterStore'
import { useVendorStore } from './vendorStore'
import { useEmployerStore } from './employerStore'
// import { useNoticeStore } from './noticeStore'
import CraftsMaster, { LoginKey } from '@/models/craftsMaster'
import Chance from 'chance'

const chance = Chance()

export const useUserStore = defineStore('user', {
	state: () => ({
		currentUserId: null,
		users: [],
		usersWithoutCompany: [],
		testUser: {}
	}),
	getters: {
		userById: state => id => state.users.find(u => u.UserId === id),
		currentUser (state) {
			let user = {}
			if (state.currentUserId) {
				user = state.users.find(u => u.UserId === state.currentUserId)
				if (user) {
					user = { ...user }
					if (user.IsAdmin) {
						user.TypeId = User.UserType.Admin
					} else if (user.hasOwnProperty('CompanyId')) {
						const company = this.companies.find(c => c.value === user.CompanyId)
						user.TypeId = company ? company.typeId : null
					} else {
						user.TypeId = null
					}
				}
			}
			return user
		},
		currentCompanyId: state => {
			const user = state.users.find(u => u.UserId === state.currentUserId)
			return user ? user.CompanyId : null
		},
		currentCompany () {
			const company = this.companies.find(c => c.value === this.currentCompanyId)
			if (company) {
				return {
					id: company.id,
					name: company.name,
					typeId: company.typeId
				}
			} else {
				return {
					id: null,
					name: '',
					typeId: null
				}
			}
		},
		currentUsers (state) {
			const users = state.users.map(u => {
				if (u.IsAdmin) {
					return {
						...u,
						ContactInfo: `${u.Phone}<br />${u.Email}`,
						Company: 'Admin',
						Type: User.UserTypes[User.UserType.Admin].text,
						TypeId: User.UserType.Admin
					}
				} else {
					const company = this.companies.find(c => c.value === u.CompanyId)
					return {
						...u,
						ContactInfo: `${u.Phone}<br />${u.Email}`,
						TypeId: company ? company.typeId : 'NA',
						Type: company ? User.UserTypes[company.typeId].text : 'Unknown',
						Company: company ? company.name : null
					}
				}
			})
			return users
		},
		companies: () => {
			const employerStore = useEmployerStore()
			const employers = employerStore.employers.map(e => ({
				id: e.EmployerId,
				value: e.EmployerId,
				text: `E:${e.Name}`,
				name: e.Name,
				typeId: User.UserType.Employer
			}))
			const vendorStore = useVendorStore()
			const vendors = vendorStore.vendors.map(v => ({
				id: v.VendorId,
				value: v.VendorId,
				text: `V:${v.Name}`,
				name: v.Name,
				typeId: User.UserType.Vendor
			}))

			return employers.concat(vendors).sort((a, b) => a.text.localeCompare(b.text))
		},
		companyById () {
			return (id) => this.companies.find(c => c.id === id)
		},
		isEmployer () {
			return this.currentUser.TypeId === User.UserType.Employer
		},
		isVendor () {
			return this.currentUser.TypeId === User.UserType.Vendor
		},
		isAdmin () {
			return this.currentUser.TypeId === User.UserType.Admin
		},
		portal () {
			return CraftsMaster.getPortalByUserType(this.currentUser.TypeId)
		},
		userByCompanyId: state => companyId => state.users.find(u => u.CompanyId === companyId && u.IsPrimary)
	},
	actions: {
		logout () {
			localStorage.removeItem(LoginKey)
			this.currentUserId = null
		},
		clearUsers () {
			this.users = []
			this.currentUserId = null
		},
		saveUser (user) {
			if (user.hasOwnProperty('UserId') && user.UserId) {
				this.updateUser(user)
			} else {
				this.addUser(user)
			}
		},
		async updateUser (user) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await UserDataService.update(user)

				if (response && response.data) {
					const updatedUser = this.users.find(u => u.UserId === response.data.UserId)
					if (updatedUser) {
						Object.assign(updatedUser, response.data)
					}

					craftsMasterStore.setSnackbar({
						value: true,
						text: `User (${response.data.Name}) updated`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `User (${user.Name} | ${user.UserId}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.response.data.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async addUser (user) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				user.UserId = chance.hash({ length: 10 })
				const response = await UserDataService.create(user)
				if (response && response.data) {
					this.users.push(response.data)

					craftsMasterStore.setSnackbar({
						value: true,
						text: `User (${response.data.Name}) added`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `User (${user.Name} | ${user.UserId}) not added`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async getUsers () {
			const craftsMasterStore = useCraftsMasterStore()
			try {
				const response = await UserDataService.getAll()
				if (response && response.data) {
					this.users = response.data.users
					this.usersWithoutCompany = response.data.usersWithoutCompany
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						text: 'No users found.',
						type: 'error'
					})
				}
			} catch (error) {
				this.clearUsers()

				craftsMasterStore.setSnackbar({
					value: true,
					text: error.message,
					type: 'error'
				})
			}
		},
		async setCurrentUserId (payload) {
			payload = payload || {
				username: '',
				passcode: ''
			}
			if (payload.username) {
				const craftsMasterStore = useCraftsMasterStore()

				let user = this.users?.find(u => u.Username === payload.username)
				if (!user) {
					user = this.usersWithoutCompany?.find(u => u.Username === payload.username)
				}
				if (user) {
					if (user.hasOwnProperty('CompanyId') && user.CompanyId === null) {
						craftsMasterStore.setSnackbar({
							value: true,
							maxWidth: '200',
							text: `
								User "${payload.username}" has no company --
								please contact CraftsMaster for assistance
							`,
							type: 'error'
						})
						this.currentUserId = null
					} else {
						this.currentUserId = user.UserId
						UserDataService.setLoginDate(this.currentUserId)
						// useNoticeStore().addNotice({
						// 	Notes: `User <span class="font-weight-bold">${payload.username}</span> logged in`
						// })
					}
				} else {
					if (payload.username === 'charlos') {
						// initial run...add admin user
						await this.addUser({
							Username: payload.username,
							Passcode: '',
							Name: 'Admin',
							Phone: '513.490.3720',
							Email: 'charles.joseph.simpson@gmail.com',
							IsAdmin: true,
							IsPrimary: true,
							Notes: 'admin account'
						})
						const user = this.users.find(u => u.Username === payload.username)
						this.currentUserId = user.UserId
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							text: `User "${payload.username}" not found`,
							type: 'error'
						})
						this.currentUserId = null
					}
				}
			} else {
				this.currentUserId = null
			}
		},
		async getPrimaryUserByCompanyId (companyId) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await UserDataService.getPrimaryByCompanyId(companyId)
				if (response && response.data) {
					const index = this.users.findIndex(u => u.UserId === response.data.UserId)
					if (index === -1) {
						this.users.push(response.data)
					}
					this.currentUserId = response.data.UserId
				} else {
					this.currentUserId = null
				}
			} catch (error) {
				this.currentUserId = null
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async deleteUser (user) {
			const craftsMasterStore = useCraftsMasterStore()

			if (user.UserId) {
				craftsMasterStore.loading = true

				try {
					const response = await UserDataService.delete(user.UserId)
					if (response) {
						const deletedUserIndex = this.users.findIndex(u => u.UserId === response.data)
						if (deletedUserIndex !== -1) {
							this.users.splice(deletedUserIndex, 1)
						}

						craftsMasterStore.setSnackbar({
							value: true,
							text: `User (${user.Name}) deleted`
						})
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `User (${user.Name} | ${user.UserId}) not deleted`
						})
					}
				} catch (error) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: error.response.data.message
					})
				} finally {
					craftsMasterStore.loading = false
				}
			} else {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: `User (${user.Name}) not deleted: no UserId`
				})
			}
		},
		setCurrentCompanyIdByUser (user) {
			user = user || this.currentUser
			if (user) {
				let companyStore
				switch (user.TypeId) {
					case User.UserType.Employer:
						companyStore = useEmployerStore()
						companyStore.setCurrentEmployerId(user.CompanyId)
						break
					case User.UserType.Vendor:
						companyStore = useVendorStore()
						companyStore.setCurrentVendorId(user.CompanyId)
						break
				}
			}
		},
		// async getTestUsers () {
		// 	localStorage.removeItem(User.UsersKey)
		// 	UserDataService.getTestUsers().then(response => {
		// 		if (response) {
		// 			this.users = this.users.concat(response.data)
		// 			localStorage.setItem(User.UsersKey, JSON.stringify(this.users))
		// 		}
		// 	}, error => {
		// 		this.clearUsers()

		// 		const craftsMasterStore = useCraftsMasterStore()
		// 		craftsMasterStore.setSnackbar({
		// 			value: true,
		// 			text: error.message,
		// 			type: 'error'
		// 		})
		// 	})
		// },
		// async getTestUser () {
		// 	const response = await UserDataService.getTestUser()

		// 	if (response) {
		// 		this.testUser = { ...response.data }
		// 	}
		// },
		async deleteAllUsers () {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				await UserDataService.deleteAll()
				this.clearUsers()
				craftsMasterStore.setSnackbar({
					value: true,
					text: 'All users deleted'
				})
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		setUserLocalStorage (user) {
			localStorage.setItem(LoginKey, JSON.stringify({
				username: user.username,
				passcode: user.passcode
			}))
		}
	}
})
