import { http } from '@/utils'
// import { useCraftsMasterStore } from '@/store/craftsMasterStore'
// import chance from 'chance'
// import CraftsMaster, { TestDataLimit } from '@/common/craftsMaster'
// import { VendorsKey } from '@/common/vendor'

// const getTestVendor = (user) => {
// 	const companyName = chance().company()
// 	let emailDomain = companyName.replace(/ /g, '').split(',')[0]
// 	emailDomain = emailDomain.split('').reverse()
// 	if (emailDomain[0] === '.') {
// 		emailDomain.shift()
// 	}
// 	emailDomain = emailDomain.reverse().join('')

// 	return {
// 		VendorId: user ? user.companyId : chance().hash({ length: 10 }),
// 		Name: companyName,
// 		Address: chance().address(),
// 		City: chance().city(),
// 		State: chance().state(),
// 		Zip: chance().zip(),
// 		Notes: chance().sentence(),
// 		_new: false
// 	}
// }

// const getTestVendors = () => {
// 	return new Promise((resolve, reject) => {
// 		const date = new Date()
// 		let vendors = localStorage.getItem(VendorsKey)
// 		if (vendors) {
// 			vendors = JSON.parse(vendors)
// 		} else {
// 			const store = useCraftsMasterStore()
// 			const vendorCount = chance().integer({
// 				min: store.testDataLimits[TestDataLimit.Vendors].range[0],
// 				max: store.testDataLimits[TestDataLimit.Vendors].range[1]
// 			})
// 			vendors = []
// 			for (let i = 0; i < vendorCount; i++) {
// 				const vendor = getTestVendor()
// 				vendors.push(vendor)
// 			}
// 			localStorage.setItem(VendorsKey, JSON.stringify(vendors))
// 		}
// 		console.log(`vendors: ${vendors.length} in ${new Date() - date} ms`)
// 		resolve({ data: vendors })
// 		reject(new Error('VendorDataService.getTestVendors: Error getting vendors'))
// 	})
// }

class VendorDataService {
	getAll () {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const date = new Date()
		// 		let vendors = localStorage.getItem(VendorsKey)
		// 		if (vendors) {
		// 			vendors = JSON.parse(vendors)
		// 		} else {
		// 			vendors = []
		// 		}
		// 		console.log(`vendors: ${vendors.length} in ${new Date() - date} ms`)
		// 		resolve({ data: vendors })
		// 		reject(new Error('VendorDataService.getAll: error getting vendors'))
		// 	})
		// } else {
		return http.get('/vendors')
		// }
	}

	getById (id) {
		return http.get(`/vendors/${id}`)
	}

	// async getTestVendors () {
	// 	const testVendors = await getTestVendors()
	// 	return http.post('/vendors/bulk', testVendors.data)
	// }

	create (data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const vendor = Object.assign({}, data)
		// 		vendor.VendorId = chance.hash({ length: 5 })
		// 		vendor._new = true
		// 		let vendors = localStorage.getItem(VendorsKey)
		// 		if (vendors) {
		// 			vendors = JSON.parse(vendors)
		// 			vendors.push(vendor)
		// 			localStorage.setItem(VendorsKey, JSON.stringify(vendors))
		// 			resolve({ data: vendor })
		// 		} else {
		// 			reject(new Error('VendorDataService.create: no vendors found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.post('/vendors', data)
		// }
	}

	update (id, data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		let vendors = localStorage.getItem(VendorsKey)
		// 		if (vendors) {
		// 			vendors = JSON.parse(vendors)
		// 			const vendor = vendors.find(e => e.EmployerId === id)
		// 			if (vendor) {
		// 				Object.assign(vendor, data)
		// 				localStorage.setItem(VendorsKey, JSON.stringify(vendors))
		// 				resolve({ data: vendor })
		// 			} else {
		// 				reject(new Error(`VendorDataService.update: vendor ${id} not found in localStorage`))
		// 			}
		// 		} else {
		// 			reject(new Error('VendorDataService.update: no vendors found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.put(`/vendors/${id}`, data)
		// }
	}

	delete (id) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		let vendors = localStorage.getItem(VendorsKey)
		// 		if (vendors) {
		// 			vendors = JSON.parse(vendors)
		// 			const vendorIndex = vendors.findIndex(v => v.VendorId === id)
		// 			if (vendorIndex !== -1) {
		// 				vendors.splice(vendorIndex, 1)
		// 				localStorage.setItem(VendorsKey, JSON.stringify(vendors))
		// 				resolve({ data: id })
		// 			} else {
		// 				reject(new Error(`VendorDataService.delete: vendor ${id} not found in localStorage`))
		// 			}
		// 		} else {
		// 			reject(new Error('VendorDataService.delete: no vendors found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.delete(`/vendors/${id}`)
		// }
	}

	deleteAll () {
		return http.delete('/vendors')
	}

	// getByUser (user) {
	// 	if (CraftsMaster.DataSource === 'test') {
	// 		return new Promise((resolve, reject) => {
	// 			let vendors = localStorage.getItem(VendorsKey)
	// 			let vendor
	// 			if (vendors) {
	// 				vendors = JSON.parse(vendors)
	// 				vendor = vendors.find(v => v.VendorId === user.companyId)
	// 				if (!vendor) {
	// 					vendor = getTestVendor(user)
	// 					vendors.push(vendor)
	// 					localStorage.setItem(VendorsKey, JSON.stringify(vendors))
	// 				}
	// 			} else {
	// 				vendor = getTestVendor(user)
	// 				localStorage.setItem(VendorsKey, JSON.stringify([vendor]))
	// 			}
	// 			resolve({ data: vendor })
	// 			reject(new Error('VendorDataService.getByUser: Unknown user'))
	// 		})
	// 	} else {
	// 		return http.get(`/vendor?user=${user}`)
	// 	}
	// }
}

export default new VendorDataService()
