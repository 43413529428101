import Vue from 'vue'

export default function () {
	Vue.component('left-menu', () => import('./LeftMenu'))
	Vue.component('column-header', () => import('./ColumnHeader'))
	Vue.component('dialog-contents', () => import('./DialogContents'))
	Vue.component('time-select', () => import('./TimeSelect'))
	Vue.component('duration-select', () => import('./DurationSelect'))
	Vue.component('field-edit-drawer', () => import('./FieldEditDrawer'))
	Vue.component('filter-menu', () => import('./FilterMenu'))
	Vue.component('job-order-card', () => import('./JobOrderCard'))
	Vue.component('file-uploader', () => import('./FileUploader'))
	Vue.component('column-layout', () => import('./ColumnLayout'))
	Vue.component('card-skeleton', () => import('./skeletons/Card'))
	Vue.component('data-table-wrapper', () => import('./DataTableWrapper'))
	Vue.component('error-dialog', () => import('./ErrorDialog'))
	Vue.component('offer-dialog', () => import('./OfferDialog'))
	Vue.component('inline-confirm-menu', () => import('./InlineConfirmMenu'))
	Vue.component('dashboard-card', () => import('./DashboardCard'))
	Vue.component('dashboard-card-container', () => import('./DashboardCardContainer'))
	Vue.component('job-order-craftsmen', () => import('./JobOrderCraftsmen'))
	Vue.component('contact-card', () => import('./ContactCard'))
	Vue.component('file-importer', () => import('./FileImporter'))
	Vue.component('simple-table', () => import('./SimpleTable'))
	Vue.component('post-job-order-dialog', () => import('./PostJobOrderDialog'))
	Vue.component('events', () => import('./Events'))
	Vue.component('event-field-edit-drawer', () => import('./EventFieldEditDrawer'))
	Vue.component('craftsman-job-order-history', () => import('./CraftsmanJobOrderHistory'))
}
