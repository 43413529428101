export const Level = {
	Low: 0,
	Medium: 1,
	High: 2
}

export const Levels = [
	{
		value: Level.Low,
		text: 'Low'
	}, {
		value: Level.Medium,
		text: 'Medium'
	}, {
		value: Level.High,
		text: 'High'
	}
]

const Field = {
	JobOrderId: 0,
	CreatedByCompanyId: 1,
	CreatedByUserId: 2,
	Level: 3,
	CraftsmanId: 4,
	StatusId: 5, // for joc
	Notes: 6,
	NoticeId: 7,
	createdAt: 8
}

const Fields = [
	{
		id: Field.JobOrderId,
		label: 'Job Order',
		width: '10%',
		value: null,
		route: true,
		sortable: true,
		groupable: true
	}, {
		id: Field.CreatedByCompanyId,
		label: 'Company',
		width: '10%',
		value: null,
		sortable: true,
		groupable: true
	}, {
		id: Field.CreatedByUserId,
		label: 'User',
		width: '10%',
		value: null,
		sortable: true,
		groupable: true
	}, {
		id: Field.Level,
		component: 'v-radio-group',
		label: 'Level',
		sortable: true,
		value: null,
		groupable: true,
		width: '7%',
		radioItems: Levels
	}, {
		id: Field.CraftsmanId,
		label: 'Craftsman',
		width: '10%',
		value: null,
		sortable: true,
		groupable: true
	}, {
		id: Field.StatusId, // joc status
		label: 'Status',
		width: '10%',
		value: null,
		sortable: true,
		groupable: true
	}, {
		id: Field.Notes,
		component: 'v-textarea',
		label: 'Notes',
		width: '20%',
		value: null
	}, {
		id: Field.NoticeId
	}, {
		id: Field.createdAt,
		label: 'Created Date',
		width: '10%',
		isDateTime: true,
		sortable: true
	}
]

const FieldNames = Object.keys(Field)

export default {
	Icon: 'mdi-bell',
	Level,
	Levels,
	Field,
	Fields,
	FieldNames
}
