<template>
	<v-app>
		<div
			v-if="loading"
			class="d-flex align-center justify-center loading-overlay"
			:class="[{ 'loading-overlay-displayed': loading }]">
			<div>
				<div class="d-flex justify-center mb-2">
					<img
						src="@/assets/cm-logo-cooper-black.png"
						class="animate__animated animate__rotateIn animate__infinite"
						contain
						style="width: 125px; height: 125px" />
				</div>
				<div class="white--text">{{ loadingText }}</div>
			</div>
		</div>
		<v-snackbar
			right top
			:color="snackbar.color"
			:timeout="snackbar.timeout"
			outlined text
			:max-width="snackbar.maxWidth"
			:multi-line="snackbar.multiline"
			:vertical="snackbar.vertical"
			:value="snackbar.value"
			@input="showSnackbar">
			{{ snackbar.text }}
			<template #action="{ attrs }">
				<v-btn
					v-bind="attrs"
					small outlined text icon
					@click="showSnackbar(false)">
					<v-icon small>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<v-navigation-drawer absolute right width="420" temporary v-model="drawerVisible">
			<component
				v-if="drawerComponent"
				:is="fieldEditDrawerComponent"
				:drawer-closed="!drawerVisible"
				@keep-drawer-open="drawerVisible = true" />
		</v-navigation-drawer>
		<v-app-bar v-if="currentUser" app flat class="white" :height="appBarHeight">
			<v-container fluid class="pa-0 fill-height">
				<v-row no-gutters align="center" class="fill-height" v-if="portal">
					<v-col class="fill-height blue-grey darken-2" cols="auto">
						<div class="fill-height d-flex align-center">
							<div class="logo-col">
								<v-menu
									offset-y
									v-model="showSupportInfo"
									@input="showSupportInfo = false">
									<template #activator="{ on, attrs }">
										<div class="d-flex justify-center">
											<v-img
												v-bind="attrs"
												v-on="on"
												src="cm-logo-cooper-black.png"
												@click="showSupportInfo = true"
												style="cursor: pointer"
												contain
												width="36" height="36" />
										</div>
										<div class="btn-text font-weight-medium">{{ portal.title }}</div>
									</template>
									<div class="grey darken-3 white--text pa-2" style="opacity: 0.9">
										<div>version: {{ version }}</div>
										<div>support number: 513.490.3720</div>
										<div>
											<span>support email: charles.simpson@craftsmaster.net</span>
											<v-btn
												icon small outlined
												color="white"
												class="ml-2"
												@click="copyId('charles.simpson@craftsmaster.net')">
												<v-icon small>mdi-content-copy</v-icon>
											</v-btn>
										</div>
									</div>
								</v-menu>
							</div>
						</div>
					</v-col>
					<v-col class="fill-height">
						<main-menu-tabs v-model="portalTab" />
					</v-col>
					<v-divider vertical />
					<v-col cols="auto" class="ml-auto d-flex align-center">
						<div class="d-none d-md-flex flex-column pl-3 greetings-font">
							<div>
								<span class="font-weight-light">Greetings, </span>
								<span class="font-weight-bold">{{ currentUser.Name }}</span>
							</div>
							<span class="text-right font-weight-thin company-font">{{ currentCompany.name }}</span>
						</div>
						<div class="text-center px-3">
							<v-btn
								@click.stop="accountDetails"
								outlined icon
								:large="largeScreenSize"
								:small="!largeScreenSize">
								<v-icon>mdi-logout</v-icon>
							</v-btn>
							<div class="btn-text-dark-faded">Logout</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-app-bar>
		<v-main>
			<router-view />
		</v-main>
		<error-dialog
			v-if="isErrorDialogOpen"
			v-model="isErrorDialogOpen"
			:error-messages="errorMessages" />
	</v-app>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/store/userStore'
import { useCraftsMasterStore } from '@/store/craftsMasterStore'
import { useCraftsmanStore } from '@/store/craftsmanStore'
import { useVendorStore } from '@/store/vendorStore'
import { useEmployerStore } from '@/store/employerStore'
import { useJobOrderStore } from '@/store/jobOrderStore'
import CraftsMaster, { Portals } from '@/models/craftsMaster'
import User from '@/models/user'
import { copyId } from '@/components/shared/DataTableWrapper'
import MainMenuTabs from '@/components/MainMenuTabs'
// import VueRouter from 'vue-router'

// const { isNavigationFailure, NavigationFailureType } = VueRouter

export default {
	name: 'App',
	components: { MainMenuTabs },
	data: () => ({
		userType: User.UserType,
		drawerVisible: false,
		version: CraftsMaster.Version,
		portalTab: '',
		showSupportInfo: false,
		copyId,
		isErrorDialogOpen: false
	}),
	async created () {
		const tab = CraftsMaster.getTabByRouteName(this.$route.name)
		if (tab) {
			this.portalTab = tab.name
		}
	},
	watch: {
		drawerComponent (val) {
			this.drawerVisible = !!val
		},
		currentUserId (val) {
			if (val && this.portal && this.portal.tabs) {
				this.setTabs(this.portal.tabs)
			}
		},
		'$route' (to) {
			if (to.name === 'login') {
				return
			}
			let tabs = []
			const tab = CraftsMaster.getTabByRouteName(to.name)

			if (tab) {
				this.portalTab = tab.name
			}

			const portal = Portals.find(p => {
				const index = to.path.indexOf(p.name)
				return index === 1
			})
			if (portal) {
				tabs = JSON.parse(JSON.stringify(portal.tabs))
			}

			const params = { ...to.params }
			if (Object.keys(params).length) {
				const name = to.name
				const tab = tabs.find(t => t.name === name)
				if (tab) {
					tab.params = { ...params }
				}
			}
			this.setTabs(tabs, tab)
		},
		errorMessages: {
			handler (val) {
				this.isErrorDialogOpen = !!(val && val.length)
			},
			deep: true
		}
	},
	computed: {
		...mapState(useUserStore, [
			'currentUserId',
			'currentUser',
			'currentCompany',
			'portal'
		]),
		...mapState(useCraftsMasterStore, [
			'drawerComponent',
			'loading',
			'loadingText',
			'snackbar',
			'tabs',
			'errorMessages'
		]),
		...mapState(useCraftsmanStore, ['craftsmen']),
		...mapState(useVendorStore, ['vendors']),
		...mapState(useEmployerStore, ['employers']),
		keymap () {
			// use with v-hotkey="keymap" attribute on v-dialog
			return {
				'alt+shift+d': this.toggleTestDataDialog
			}
		},
		largeScreenSize () {
			const screenSize = this.$vuetify.breakpoint.name
			return screenSize === 'lg' || screenSize === 'xl'
		},
		appBarHeight () {
			const appBarHeight = getComputedStyle(document.documentElement).getPropertyValue('--main-app-bar-height')
			return appBarHeight
		},
		fieldEditDrawerComponent () {
			return this.drawerComponent?.isEventDrawer ? 'event-field-edit-drawer' : 'field-edit-drawer'
		}
	},
	methods: {
		...mapActions(useUserStore, ['logout']),
		...mapActions(useCraftsMasterStore, [
			'showSnackbar',
			'setTabs'
		]),
		...mapActions(useJobOrderStore, ['clearJobOrders']),
		...mapActions(useCraftsmanStore, ['clearCraftsmen']),
		getVh () {
			const vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
			// document.documentElement.style.removeProperty('--vh')
			// :root var retrieval
			// const tabHeight = window.getComputedStyle(document.body).getPropertyValue('--tab-height')
		},
		toggleTestDataDialog () {
			// this.showTestDataDialog = CraftsMaster.DataSource !== 'remote' && !this.showTestDataDialog
		},
		accountDetails () {
			// TODO: just logging out for now and returning to login
			this.logout()
			this.$router.push('/')
		}
	}
}
</script>
<style lang="scss">
@use './styles/cm';

html,
body {
	overflow: hidden !important;
}

.v-toolbar__content {
	padding: 0 !important;
}

.right-border {
	border-right: solid 1px #bdbdbd;
}

.greetings-font {
	font-size: 0.875rem;
	.company-font {
		font-size: 0.75rem;
	}

	@media screen and (max-width: 1264px) {
		font-size: 0.75rem;
		.company-font {
			font-size: 0.68rem;
		}
	}

	@media screen and (max-width: 980px) {
		font-size: 0.68rem;
		.company-font {
			font-size: 0.6rem;
		}
	}
}

// fixes vuetify bug with keep-alive inside hide-on-leave transition
// .portal-container {
// 	display: flex !important;
// }
</style>
<style lang="scss" scoped>
@use './styles/cm';

.logo-col {
	width: cm.$left-menu-icon-col-width;
	max-width: cm.$left-menu-icon-col-width;
	min-width: cm.$left-menu-icon-col-width;
}
.loading-overlay {
	z-index: 200;
	position: fixed;
	background-color: black;
	height: 0;
	width: 0;
	opacity: 0;
}
.loading-overlay-displayed {
	opacity: 0.7;
	height: 100vh;
	width: 100vw;
	transition: opacity 1s;
}
</style>
