import { http } from '@/utils'

class EventDataService {
	getAll () {
		return http.get('/events')
	}

	getById (id) {
		return http.get(`/events/${id}`)
	}

	getByJobOrderId (id) {
		return http.get(`/events/jobOrder/${id}`)
	}

	getByEmployerId (id) {
		return http.get(`/events/employer/${id}`)
	}

	getByVendorId (id) {
		return http.get(`/events/vendor/${id}`)
	}

	create (data) {
		return http.post('/events', data)
	}

	update (id, data) {
		return http.put(`/events/${id}`, data)
	}

	delete (id) {
		return http.delete(`/events/${id}`)
	}

	deletePending (payload) {
		return http.delete(`/events/pending/${payload.jobOrderId}/${payload.craftsmanId}/${payload.typeId}`)
	}

	deleteJobOrderCraftsman (payload) {
		return http.delete(`/events/jobOrder/${payload.jobOrderId}/craftsman/${payload.craftsmanId}`)
	}

	deleteEvents (ids) {
		return http.patch('/events/delete', ids)
	}
}

export default new EventDataService()
