import { defineStore } from 'pinia'
// import Employer from '@/common/employer'
import JobOrder from '@/models/jobOrder'
import EmployerDataService from '@/services/EmployerDataService'
import { useCraftsMasterStore } from './craftsMasterStore'
import { useVendorStore } from './vendorStore'
import { useNoticeStore } from './noticeStore'
import Chance from 'chance'

const chance = Chance()

export const useEmployerStore = defineStore('employer', {
	state: () => ({
		testEmployer: {},
		employers: [],
		currentEmployerId: null
	}),
	getters: {
		currentEmployer: state => state.employers.find(e => e.EmployerId === state.currentEmployerId),
		adminEmployers (state) {
			const vendors = useVendorStore().vendors
			const employers = state.employers.map(e => {
				const vendorIds = e.Vendors.map(v => v.VendorId)
				const vendorList = vendors
					.filter(v => vendorIds.includes(v.VendorId))
					.map(v => v.Name)
					.sort((a, b) => a.localeCompare(b))
				const yardList = e.Yards.map(y => y.Name)
				return {
					...e,
					vendorList,
					yardList
				}
			})
			return employers
		},
		employerVendors: state => employerId => {
			employerId = employerId || state.currentEmployerId
			const employer = state.employers.find(e => e.EmployerId === employerId)
			if (employer) {
				const vendorIds = employer.Vendors.map(v => v.VendorId)
				const vendors = useVendorStore().vendors.filter(v => vendorIds.includes(v.VendorId))
				return vendors.map(v => ({
					text: v.Name,
					value: v.VendorId,
					VendorId: v.VendorId,
					StatusId: JobOrder.Status.Created
				}))
			} else {
				return []
			}
		},
		employerById: state => id => state.employers.find(e => e.EmployerId === id),
		employerList: state => state.employers
			.map(e => ({
				text: e.Name,
				value: e.EmployerId
			}))
			.sort((a, b) => a.text.localeCompare(b.text))
	},
	actions: {
		clearEmployers () {
			this.employers = []
		},
		async getEmployers () {
			try {
				const response = await EmployerDataService.getAll()
				if (response) {
					this.employers = response.data
				}
			} catch (error) {
				this.employers = []

				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.message,
					type: 'error'
				})
			}
		},
		async getEmployerById (id) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await EmployerDataService.getById(id)
				if (response && response.data) {
					const index = this.employers.findIndex(e => e.EmployerId === response.data.EmployerId)
					if (index === -1) {
						this.employers.push(response.data)
					}
					this.currentEmployerId = response.data.EmployerId
				} else {
					this.currentEmployerId = null
				}
			} catch (error) {
				this.currentEmployerId = null
			} finally {
				craftsMasterStore.loading = false
			}
		},
		setCurrentEmployerId (id) {
			this.currentEmployerId = id
		},
		saveEmployer (employer, options = {}) {
			if (employer.hasOwnProperty('EmployerId') && employer.EmployerId) {
				this.updateEmployer(employer)
			} else {
				this.addEmployer(employer, options)
			}
		},
		async addEmployer (employer, options = {}) {
			const craftsMasterStore = useCraftsMasterStore()
			if (!options.silentMode) {
				craftsMasterStore.loading = true
			}

			try {
				employer.EmployerId = chance.hash({ length: 10 })
				employer.EmployerCode = employer.EmployerCode || employer.Name.slice(0, 3).trim().toUpperCase()

				// skip if an employer exists with the same name
				const employerExists = this.employers.findIndex(e => {
					return (e.Name === employer.Name ||
						e.EmployerId === employer.EmployerId ||
						e.EmployerCode === employer.EmployerCode) !== -1
				})
				if (employerExists) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Employer (${employer.Name}) not added: duplicate name, id, or code`
					})
					return
				}
				if (!employer.Vendors) {
					employer.Vendors = []
				}
				employer.Yards = []
				const response = await EmployerDataService.create(employer)
				if (response && response.data) {
					this.employers.push(response.data)

					if (!options.silentMode) {
						craftsMasterStore.setSnackbar({
							value: true,
							text: `Employer (${response.data.Name}) added`
						})
					}

					useNoticeStore().addNotice({
						Notes: `Employer <span class="font-weight-bold">${response.data.Name}</span> added`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Employer (${employer.Name} | ${employer.EmployerId}) not added`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response ? error.response.data.message : error.message
				})
			} finally {
				if (!options.silentMode) {
					craftsMasterStore.loading = false
				}
			}
		},
		async updateEmployer (employer) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await EmployerDataService.update(employer.EmployerId, employer)
				if (response && response.data) {
					const updatedEmployer = this.employers.find(e => e.EmployerId === response.data.EmployerId)
					if (updatedEmployer) {
						Object.assign(updatedEmployer, response.data)
					}

					craftsMasterStore.setSnackbar({
						value: true,
						text: `Employer (${response.data.Name}) updated`
					})

					useNoticeStore().addNotice({
						Notes: `Employer <span class="font-weight-bold">${response.data.Name}</span> updated`
					})
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Employer (${employer.Name} | ${employer.EmployerId}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response ? error.response.data.message : error.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async deleteEmployer (employer) {
			const craftsMasterStore = useCraftsMasterStore()

			if (employer.EmployerId) {
				craftsMasterStore.loading = true

				try {
					const response = await EmployerDataService.delete(employer.EmployerId)
					if (response) {
						const deletedEmployerIndex = this.employers.findIndex(e => e.EmployerId === response.data)
						if (deletedEmployerIndex !== -1) {
							this.employers.splice(deletedEmployerIndex, 1)
						}

						craftsMasterStore.setSnackbar({
							value: true,
							text: `Employer (${employer.Name}) deleted`
						})
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `Employer (${employer.Name} | ${employer.EmployerId}) not deleted`
						})
					}
				} catch (error) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: error.response.data.message
					})
				} finally {
					craftsMasterStore.loading = false
				}
			} else {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: `Employer (${employer.Name}) not deleted: no EmployerId`
				})
			}
		},
		async deleteAllEmployers () {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				await EmployerDataService.deleteAll()
				this.clearEmployers()
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				craftsMasterStore.loading = false
			}
		}
		// async getTestEmployers () {
		// 	localStorage.removeItem(Employer.EmployersKey)
		// 	EmployerDataService.getTestEmployers().then(response => {
		// 		if (response) {
		// 			this.employers = this.employers.concat(response.data)
		// 			localStorage.setItem(Employer.EmployersKey, JSON.stringify(this.employers))
		// 		}
		// 	}, error => {
		// 		this.employers = []

		// 		const craftsMasterStore = useCraftsMasterStore()
		// 		craftsMasterStore.setSnackbar({
		// 			value: true,
		// 			text: error.message,
		// 			type: 'error'
		// 		})
		// 	})
		// },
		// async getTestEmployer () {
		// 	const response = await EmployerDataService.getTestEmployer()

		// 	if (response) {
		// 		this.testEmployer = { ...response.data }
		// 	}
		// }
	}
})
