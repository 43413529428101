import CraftsMaster from '@/models/craftsMaster'
import { useVendorStore } from '@/store/vendorStore'
import { useEmployerStore } from '@/store/employerStore'
import Chance from 'chance'

const chance = Chance()

const Field = {
	Name: 0,
	Address: 1,
	City: 2,
	State: 3,
	Zip: 4,
	Notes: 5,
	Yards: 6,
	Vendors: 7,
	EmployerId: 8,
	EmployerCode: 9
}

const Fields = [{
	id: Field.Name,
	component: 'v-text-field',
	label: 'Company Name',
	value: null,
	width: '15%',
	sortable: true,
	required: true,
	importable: true,
	rando: () => chance.company()
}, {
	id: Field.Address,
	component: 'v-text-field',
	label: 'Address',
	width: '15%',
	type: 'text',
	value: null,
	required: true,
	importable: true,
	rando: () => chance.address()
}, {
	id: Field.City,
	component: 'v-text-field',
	label: 'City',
	width: '10%',
	sortable: true,
	type: 'text',
	value: null,
	required: true,
	importable: true,
	rando: () => chance.city()
}, {
	id: Field.State,
	component: 'v-select',
	label: 'State',
	value: null,
	width: '7%',
	sortable: true,
	items: CraftsMaster.StateList,
	cols: 4,
	required: true,
	importable: true,
	rando: () => chance.state()
}, {
	id: Field.Zip,
	component: 'v-text-field',
	label: 'Zip',
	sortable: true,
	width: '6%',
	value: null,
	cols: 8,
	required: true,
	importable: true,
	rando: () => chance.zip()
}, {
	id: Field.Notes,
	component: 'v-textarea',
	label: 'Notes',
	width: '15%',
	value: null
}, {
	id: Field.Yards,
	component: 'v-select',
	label: 'Yards',
	width: '8%',
	value: null,
	multiple: true,
	returnObject: true,
	clearable: false,
	chips: true,
	items: null,
	readonly: true,
	valueField: 'YardId'
}, {
	id: Field.Vendors,
	component: 'v-select',
	label: 'Vendors',
	width: '8%',
	value: null,
	multiple: true,
	returnObject: true,
	chips: true,
	items: null,
	valueField: 'VendorId'
}, {
	id: Field.EmployerId
}, {
	id: Field.EmployerCode,
	component: 'v-text-field',
	label: 'Employer Code',
	value: null,
	width: '10%',
	required: true
}]

export const YardField = {
	Name: 0,
	Employer: 1,
	Areas: 2,
	Address: 3,
	City: 4,
	State: 5,
	Zip: 6,
	YardId: 7
}

export const YardFields = [{
	id: YardField.Name,
	component: 'v-text-field',
	label: 'Name',
	value: null,
	width: '12%',
	required: true,
	sortable: true,
	rando: () => {
		return `${chance.last()} Shipyard`
	}
}, {
	id: YardField.Employer,
	component: 'v-select',
	label: 'Employer',
	width: '12%',
	value: null,
	required: true,
	returnObject: true,
	groupable: true,
	sortable: true,
	items: null,
	valueField: 'EmployerId'
}, {
	id: YardField.Areas,
	component: 'v-combobox',
	label: 'Areas',
	width: '10%',
	value: null,
	required: true,
	multiple: true,
	chips: true,
	items: null,
	rando: () => {
		const areas = new Set()
		const maxAreas = chance.integer({ min: 1, max: 4 })
		for (let i = 0; i < maxAreas; i++) {
			const yardAreas = ['Warehouse', 'Dock', 'Wharf', 'Workshop', 'Port']
			const area = `${chance.pickone(yardAreas)} ${(chance.bool() ? chance.word().toUpperCase() : chance.integer({ min: 1, max: 7 }))}`
			areas.add(area)
		}
		return [...areas]
	}
}, {
	id: YardField.Address,
	component: 'v-text-field',
	label: 'Address',
	width: '12%',
	type: 'text',
	required: true,
	value: null,
	rando: () => chance.address()
}, {
	id: YardField.City,
	component: 'v-text-field',
	label: 'City',
	width: '10%',
	sortable: true,
	groupable: true,
	type: 'text',
	required: true,
	value: null,
	rando: () => chance.city()
}, {
	id: YardField.State,
	component: 'v-select',
	label: 'State',
	value: null,
	width: '7%',
	sortable: true,
	groupable: true,
	required: true,
	items: CraftsMaster.StateList,
	cols: 4,
	rando: () => chance.state()
}, {
	id: YardField.Zip,
	component: 'v-text-field',
	label: 'Zip',
	sortable: true,
	groupable: true,
	width: '7%',
	required: true,
	value: null,
	cols: 8,
	rando: () => chance.zip()
}, {
	id: YardField.YardId
}]

const FieldNames = Object.keys(Field)
export const YardFieldNames = Object.keys(YardField)

export const EmployersKey = 'craftsmaster:employers'

export default {
	Icon: 'mdi-ship-wheel',
	Field,
	Fields,
	getFields () {
		const vendors = useVendorStore().vendors
			.map(v => ({
				text: v.Name,
				value: v.VendorId,
				VendorId: v.VendorId
			}))
			.sort((a, b) => {
				return ('' + a.text).localeCompare(b.text)
			})

		const fields = Fields.map(f => {
			switch (f.id) {
				case Field.Vendors:
					return Object.assign({}, f, {
						items: vendors
					})
				default:
					return { ...f }
			}
		})
		return fields
	},
	getYardInfo (jobOrder) {
		const employer = useEmployerStore().employers.find(e => e.EmployerId === jobOrder.EmployerId)
		let Name = ''
		let Area = ''
		if (employer) {
			const yardAreaId = jobOrder.YardAreaId?.split('.')
			const yard = employer.Yards.find(y => y.YardId === yardAreaId[0])
			if (yard) {
				Name = yard.Name
				Area = yard.Areas[yardAreaId[1]]
			}
		}

		return {
			Name,
			Area
		}
	},
	FieldNames,
	YardField,
	YardFields,
	YardFieldNames,
	EmployersKey,
	ImportFieldNames: Fields.filter(f => f.importable).map(f => FieldNames[f.id])
}
