<template>
	<v-card tile class="elevation-0 crafts-master-table-container">
		<!-- <v-icon v-if="icon" class="background-image">{{ icon }}</v-icon> -->
		<v-card-title :class="color" class="lighten-3 align-center justify-start py-0 flex-nowrap" :style="{ height: filterBarHeight }">
			<div class="mr-4 fancy-title d-flex align-center">
				<span v-for="(word, index) in fancyTitle" :key="index">
					<span v-if="index > 0">&nbsp;</span>
					<span class="capital-letter">{{ word.firstLetter }}</span>
					<span class="lowercase-letter">{{ word.minusFirstLetter }}</span>
				</span>
				<v-badge
					:value="items.length"
					offset-y="14"
					offset-x="10"
					:color="`${color} darken-1`"
					:content="items.length">
					<v-icon v-if="icon" color="grey darken-4" class="ml-2 table-icon">{{ icon }}</v-icon>
				</v-badge>
			</div>
			<v-divider vertical class="mx-4" />
			<div class="d-flex flex-nowrap align-center">
				<div v-if="!hideGroupButtons" class="d-flex">
					<div v-if="showGroupToggle" class="mr-2" style="max-width: 50px; opacity: 0.8">
						<v-btn
							@click="toggleGroups"
							:disabled="!isGroupingActive"
							fab x-small outlined>
							<v-icon>{{ allGroupsOpen ? 'mdi-arrow-expand-down' : 'mdi-arrow-expand-up' }}</v-icon>
						</v-btn>
						<div class="btn-text-dark">
							{{ `${allGroupsOpen ? 'Close' : 'Expand'} All Groups` }}
						</div>
					</div>
					<div style="max-width: 50px; opacity: 0.8">
						<v-btn
							@click="removeGroups"
							:disabled="!isGroupingActive"
							fab x-small outlined>
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<div class="btn-text-dark">
							<div>Stop</div>
							<div>Grouping</div>
						</div>
					</div>
				</div>
				<div
					v-if="!hideRecentlyUpdatedButton"
					:class="{ 'ml-1': !hideGroupButtons }"
					style="min-width: 65px; max-width: 70px; opacity: 0.8">
					<v-btn
						@click="sortByUpdatedAt"
						fab x-small outlined>
						<v-icon>mdi-sort-calendar-ascending</v-icon>
					</v-btn>
					<div class="btn-text-dark">
						<div>Sort Recently</div>
						<div>Updated</div>
					</div>
				</div>
			</div>
			<v-divider vertical class="mx-4" />
			<v-text-field
				style="min-width: 250px"
				v-model="search"
				class="mt-4"
				label="Text Search"
				dense
				prepend-icon="mdi-text-search"
				:hint="itemsDisplayedText"
				persistent-hint
				clearable />
		</v-card-title>
		<v-data-table
			v-show="items.length && !loading"
			:headers="headers"
			:items="items"
			:height="`calc(100vh - (64px + ${filterBarHeight} + ${footerHeight})`"
			:ref="tableRef"
			@pagination="filter"
			:items-per-page="-1"
			disable-pagination
			@current-items="setCurrentItems"
			:search="search"
			@update:sort-by="onUpdate"
			@update:group-by="onUpdate"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:group-by="initialGroup"
			:group-desc="groupSortDesc"
			fixed-header
			:expanded.sync="localExpanded"
			:item-key="itemId"
			class="animate__animated animate__fadeIn"
			hide-default-header
			hide-default-footer>
			<template #header="{ on: { group, sort } }">
				<thead>
					<tr>
						<th
							v-for="(header, index) in headers"
							:key="index"
							:width="header.width"
							class="elevation-5 font-weight-light white--text"
							:class="[
								{ 'text-center': header.type === 'expand' },
								{ 'size-small': header.small },
								color, 'darken-3'
							]">
							<div v-if="showSelect && header.selectAll" class="text-center">
								<v-simple-checkbox
									@input="selectAll"
									:indeterminate="indeterminate"
									:value="allSelected"
									dark
									hide-details
									class="mt-0 pt-0" />
							</div>
							<div v-else
								class="d-flex align-center justify-space-between fill-height">
								<div v-if="header.icon">
									<div class="d-flex justify-center">
										<v-icon color="white">{{ header.icon }}</v-icon>
										<v-icon
											v-if="header.iconPostFix"
											x-small color="white">
											{{ header.iconPostFix }}
										</v-icon>
									</div>
									<div class="btn-text text-wrap">{{ header.text }}</div>
								</div>
								<div v-else style="display: inline-block; line-height: 95%">
									{{ header.text }}
								</div>
								<div :class="color" class="d-flex darken-2 px-1 rounded" style="opacity: 0.7">
									<div v-if="header.groupable">
										<div class="d-flex justify-center">
											<v-btn
												icon dark
												:outlined="header.grouped"
												@click.stop="myGroup(header, group)">
												<v-icon>mdi-format-list-group</v-icon>
											</v-btn>
										</div>
										<div class="btn-text">group</div>
									</div>
									<v-divider v-if="header.groupable && header.sortable" vertical dark class="my-2 mx-1" />
									<div v-if="header.sortable">
										<div class="d-flex justify-center">
											<v-btn
												icon dark
												:disabled="header.grouped"
												:outlined="header.sortIcon !== 'mdi-unfold-more-horizontal'"
												@click.stop="mySort(header, sort)">
												<v-icon>
													{{ header.sortIcon }}
												</v-icon>
											</v-btn>
										</div>
										<div class="btn-text">sort</div>
									</div>
								</div>
							</div>
						</th>
					</tr>
				</thead>
			</template>
			<template #[`group.header`]="{ group, isOpen, toggle, remove, groupBy, items }">
				<td
					:colspan="headers.length"
					:ref="group"
					groupcell
					:data-open="isOpen"
					class="group-color white--text"
					style="text-align: left;">
					<div class="row align-center ma-0">
						<v-checkbox
							v-if="showSelect"
							@change="groupSelect({
								group,
								groupBy
							})"
							:ref="`group-select-${group}`"
							:indeterminate="isGroupCheckboxIndeterminate(items)"
							:value="groupCheckboxValue(items)"
							:disabled="isGroupCheckboxDisabled(items)"
							dark
							hide-details
							style="margin-left: -2px"
							class="mt-0 pt-0 mr-4" />
						<div
							v-if="showGroupToggle"
							@click="onToggle(toggle)"
							style="cursor: pointer"
							class="d-flex align-center">
							<v-btn @click="remove" ref="removeGroups" class="d-none" />
							<span v-html="groupDisplayText(items, group, groupBy)" />
							<div
								v-if="items.length > 1"
								style="border-left: thin solid #bbb"
								class="d-inline-flex font-weight-thin align-center ml-2">
								<span class="ml-2" style="font-size: 90%">{{ items.length }}</span>
							</div>
							<v-btn
								outlined icon
								color="white"
								class="ml-3"
								style="width: 24px !important; height: 24px !important">
								<v-icon color="white">
									{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
								</v-icon>
							</v-btn>
						</div>
						<div v-else class="d-flex align-center">
							<v-btn @click="remove" ref="removeGroups" class="d-none"></v-btn>
							<span v-html="groupDisplayText(items, group, groupBy)" />
							<div
								v-if="items.length > 1"
								style="border-left: thin solid #bbb"
								class="d-inline-flex font-weight-thin align-center ml-2">
								<span class="ml-2" style="font-size: 90%">{{ items.length }}</span>
							</div>
						</div>
					</div>
				</td>
			</template>
			<template #item="{ item }">
				<tr v-if="item"
					:id="`${item[itemId]}`"
					:class="[
						{ 'elevation-24 highlight-selected font-weight-bold': isItemSelected(item[itemId]) },
						{ 'highlight-primary highlight-text-primary': item.Archived || item.isInactive },
						{ 'text--secondary': item.disabled },
						{ 'text--disabled': isItemDisabled(item[itemId]) },
						{ 'animate__animated animate__flash animate__repeat-2': !preventFlashRow && currentAnchor === `${item[itemId]}`}
					]">
					<td
						v-for="(header, colIndex) in headers"
						:key="`col-${colIndex}-${item[itemId]}`"
						:class="[
							'text-left',
							{ 'text-center': header.value === 'actions' },
							{ 'text-right': header.type === 'number' },
							{ 'group-column': header.value === currentGroup && !isItemSelected(item[itemId])}
						]">
						<div v-if="isIntersecting[`${item[itemId]}`]">
							<slot :name="`item.${header.value}`" :headers="headers" :item="item">
								<div v-if="showSelect && header.value === 'select'" class="text-center">
									<v-simple-checkbox
										:value="item._selected"
										@input="selectItem(item, $event)"
										class="mt-0 pt-0"
										hide-details />
								</div>
								<div v-else-if="header.type === 'document'" class="text-center">
									<v-btn
										icon
										dark
										color="teal darken-3"
										:disabled="!item.ResumeFormData || item.disabled"
										@click="showFile(item)">
										<v-icon>mdi-file-document</v-icon>
									</v-btn>
								</div>
								<div v-else-if="header.route">
									<div class="row no-gutters align-center my-0 py-1">
										<div v-if="item.routeIcon" class="col-auto py-0">
											<v-btn
												icon outlined
												color="blue"
												:disabled="item.disabled"
												@click="gotoRoute(item)"
												:title="item.routeTitle || ''">
												<v-icon>{{ item.routeIcon }}</v-icon>
											</v-btn>
										</div>
										<div class="col pl-1 py-0">
											<span v-html="item[header.value]" />
										</div>
									</div>
								</div>
								<div v-else-if="header.copyId">
									<div
										class="blue--text"
										style="cursor: pointer;"
										:title="item[itemId]"
										@click="copyId(item[itemId])">
										{{ item[header.value] }}
									</div>
								</div>
								<div v-else-if="header.value === 'data-table-expand'" class="text-center">
									<v-btn
										icon
										:disabled="item.disabled"
										@click="$emit('row-expanded', item)">
										<v-icon>{{ item.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
									</v-btn>
								</div>
								<div v-else-if="header.value === 'actions'" class="text-center">
									<v-btn
										icon v-for="(btn, btnIndex) in actionButtons()"
										:key="`btn-${btnIndex}-${colIndex}`"
										:class="{ 'd-none': btn.text === 'Confirm' || btn.text === 'Cancel' }"
										:disabled="(btn.text === 'Delete' && doNotDelete(item)) || item.disabled"
										@click="btn.click(item)"
										:ref="`btn-${btn.text}-${item[itemId]}`"
										:color="btn.color">
										<v-icon>{{ btn.icon }}</v-icon>
									</v-btn>
								</div>
								<div v-else-if="header.isCurrency">
									<span v-if="!item[header.value]">--</span>
									<span v-else>$ {{ item[header.value] }}</span>
								</div>
								<span v-else-if="header.isDate">{{ moment(item[header.value]).utc().format('YYYY-MM-DD') }}</span>
								<span v-else-if="header.isDateTime">{{ moment(item[header.value]).format('YYYY-MM-DD h:mm a') }}</span>
								<span v-else v-html="item[header.value]" />
							</slot>
						</div>
						<v-skeleton-loader v-else type="table-cell" />
					</td>
				</tr>
			</template>
			<template #expanded-item="{ headers, item }">
				<slot name="expanded-item" :headers="headers" :item="item" />
			</template>
			<template #footer>
				<div :class="color" class="d-flex justify-space-between lighten-4 px-4" :style="{ height: footerHeight }">
					<div class="d-flex align-start pt-1">
						<div
							v-if="legendItems"
							:class="[`${color}--text`, 'text--darken-4']"
							class="button-key text-caption white font-weight-medium px-1 rounded-l">
							<span>Button key</span>
						</div>
						<div
							v-for="(col, key, colIndex) in legendItems" :key="colIndex" class="d-flex">
							<template v-for="(btn, key, btnIndex) in col">
								<div :key="'btn-' + btnIndex" class="px-2">
									<div>
										<v-icon
											:color="btn.icon.color"
											small
											class="white rounded-circle pa-1">
											{{ btn.icon.name }}
										</v-icon>
									</div>
									<div class="btn-text-dark" style="max-width: 75px" v-html="btn.text" />
								</div>
							</template>
							<v-divider v-if="colIndex < Object.keys(legendItems).length - 1" vertical class="mx-2" />
						</div>
					</div>
					<div class="d-flex align-center py-2">
						<div>
							<v-btn fab x-small outlined @click="scrollToTop">
								<v-icon>mdi-arrow-collapse-left</v-icon>
							</v-btn>
							<div class="btn-text-dark">to top</div>
						</div>
						<div @click="scrollToBottom" class="ml-4">
							<v-btn fab x-small outlined>
								<v-icon>mdi-arrow-collapse-right</v-icon>
							</v-btn>
							<div class="btn-text-dark">to bottom</div>
						</div>
					</div>
				</div>
			</template>
		</v-data-table>
		<div v-if="!loading" style="height: 75vh" class="d-flex align-center justify-center">
			<div>
				<div v-if="items.length" class="d-flex justify-center mb-2">
					<img
						src="@/assets/cm-logo-cooper-black.png"
						class="animate__animated animate__rotateIn animate__infinite"
						style="width: 125px; height: 125px" />
				</div>
				<div>{{ loadingMessage }}</div>
			</div>
		</div>
	</v-card>
</template>
<script>
import { Direction, wordSplit } from '@/utils'
import { useCraftsMasterStore } from '@/store/craftsMasterStore'
import { mapActions, mapState } from 'pinia'
import moment from 'moment'

export function copyId (id) {
	navigator.clipboard.writeText(id)

	useCraftsMasterStore().setSnackbar({
		value: true,
		text: `${id} copied`
	})
}

export function gotoRoute (item) {
	// "this" points to the parent component, so "itemId" is coming from the parent component's data function
	const craftsMasterStore = useCraftsMasterStore()
	if (this.itemId) {
		craftsMasterStore.setCurrentAnchor(`${item[this.itemId]}`)
	}
	this.$router
		.push(item.route)
		.catch(error => {
			if (error.name != 'NavigationDuplicated') {
				throw error
			}
		})
}

export default {
	props: {
		field: Object,
		fields: Array,
		fieldNames: {
			type: Array,
			default: () => []
		},
		title: String,
		items: {
			type: Array,
			default: () => [],
			required: true
		},
		itemId: {
			type: String, // should uniquely identify a row
			required: true
		},
		initialSort: String,
		initialSortDirection: {
			type: String,
			default: 'asc'
		},
		initialGroup: String,
		initialGroupSortDirection: {
			type: String,
			default: 'asc'
		},
		initialClosedGroups: {
			type: Array,
			default: () => []
		},
		expanded: {
			type: Array,
			default: () => null
		},
		expandedHeader: {
			type: Object,
			default: () => ({
				text: '',
				width: '5%'
			})
		},
		showSelect: Boolean,
		showGroupToggle: Boolean,
		hideGroupButtons: Boolean,
		isItemSelected: {
			type: Function,
			default: () => {
				return false
			}
		},
		isItemDisabled: {
			type: Function,
			default: () => {
				return false
			}
		},
		selectItem: {
			type: Function,
			default: () => {
				return false
			}
		},
		doNotDelete: {
			type: Function,
			default: () => {
				return false
			}
		},
		additionalActionButtons: {
			type: Array,
			default: () => []
		},
		hideRecentlyUpdatedButton: Boolean,
		preventFlashRow: Boolean,
		legendItems: {
			type: Object,
			default: () => null
		}
	},
	data: () => ({
		search: '',
		footerHeight: '56px',
		filterBarHeight: '59px',
		rowHeight: 48,
		tableRef: 'crafts-master-table',
		headers: [],
		allGroupsOpen: true,
		isGroupingActive: true,
		currentGroup: null,
		Direction,
		count: 0,
		localExpanded: [],
		sortBy: null,
		sortDesc: false,
		groupSortDesc: false,
		moment,
		copyId,
		gotoRoute,
		isIntersecting: {}
	}),
	created () {
		this.localExpanded = this.expanded ? JSON.parse(JSON.stringify(this.expanded)) : []
		this.sortBy = this.initialSort
		this.sortDesc = this.initialSortDirection !== 'asc'
		this.groupSortDesc = this.initialGroupSortDirection !== 'asc'
		this.getHeaders()
		const groupHeader = this.headers.find(h => h.value === this.initialGroup)
		if (groupHeader) {
			groupHeader.grouped = true
			this.currentGroup = this.initialGroup
		}
		const sortHeader = this.headers.find(h => h.value === this.fieldNames[this.field[this.initialSort]])
		if (sortHeader) {
			sortHeader.sortIcon = this.sortDesc ? 'mdi-menu-down' : 'mdi-menu-up'
		}
	},
	mounted () {
		setTimeout(() => {
			this.onIntersect()
		})
		this.tableContainer.addEventListener('scroll', (event) => {
			this.onIntersect(event.target)
		})

		this.initialClosedGroups.forEach(g => {
			this.toggleGroup(g)
		})
		if (this.isAnchorFound) {
			try {
				this.$scrollTo(`#${this.currentAnchor}`, 100, {
					container: this.tableContainer,
					offset: -96
				})
			} catch (e) { }
		}
	},
	watch: {
		expanded: {
			handler (val) {
				if (JSON.stringify(this.localExpanded) !== JSON.stringify(val)) {
					this.localExpanded = JSON.parse(JSON.stringify(val))
				}
			},
			deep: true
		},
		fields: {
			handler (val) {
				if (JSON.stringify(this.headers) !== JSON.stringify(val)) {
					this.getHeaders()
				}
			},
			deep: true
		},
		items: {
			handler (val) {
				this.onUpdate()
			},
			deep: true
		}
	},
	computed: {
		...mapState(useCraftsMasterStore, [
			'currentAnchor',
			'currentTab',
			'loading'
		]),
		tableContainer () {
			return this.$refs[this.tableRef].$el.querySelector('.v-data-table__wrapper')
		},
		isAnchorFound () {
			let anchor = false
			try {
				anchor = this.$refs[this.tableRef].$el.querySelector(`#${this.currentAnchor}`)
			} catch (e) {
				return false
			}
			return !!anchor
		},
		indeterminate () {
			const selected = this.items.filter(i => i._selected)
			return !!selected.length && selected.length !== this.items.length
		},
		allSelected () {
			const selected = this.items.filter(i => i._selected)
			return !!selected.length && selected.length === this.items.length
		},
		fancyTitle () {
			return wordSplit(this.title)
		},
		color () {
			return this.currentTab.color || 'blue-grey'
		},
		icon () {
			return this.currentTab.icon || 'mdi-table-large'
		},
		itemsDisplayedText () {
			return this.count !== this.items.length ? `${this.title.toLowerCase()} displayed: ${this.count}` : ''
		},
		loadingMessage () {
			if (this.items.length === 0) {
				return `No ${this.title} to display`
			}
			return `Loading ${this.items.length} ${this.title}...`
		}
	},
	methods: {
		...mapActions(useCraftsMasterStore, [
			'setCurrentAnchor',
			'setTextSearchItems',
			'setLoading'
		]),
		filter (event) {
			this.count = event.itemsLength
		},
		onIntersect (target) {
			if (!target) {
				target = this.tableContainer
			}

			if (target) {
				this.isIntersecting = {}

				const rows = target.children[0].rows
				const rowCount = rows.length
				const scrollHeight = target.scrollHeight
				const rowHeight = scrollHeight / rowCount
				const viewportRowCount = Math.ceil(target.clientHeight / rowHeight) + 5
				const scrollTop = target.scrollTop
				const topRowIndex = Math.floor(scrollTop / rowHeight)

				for (let i = topRowIndex; i < topRowIndex + viewportRowCount; i++) {
					if (i < rows.length) {
						const itemId = rows[i].id
						if (itemId) {
							this.$set(this.isIntersecting, itemId, true)
						}
					}
				}
			}
		},
		scrollToTop () {
			try {
				const rows = this.tableContainer.children[0].rows
				if (rows && rows.length) {
					const topItem = [...rows].find(r => !!r.id)
					if (topItem) {
						this.$scrollTo(`#${topItem.id}`, 100, {
							container: this.tableContainer,
							offset: -96
						})
					}
				}
			} catch (e) { }
		},
		scrollToBottom () {
			try {
				const length = this.tableContainer.children[0].rows.length
				const bottomItemId = this.tableContainer.children[0].rows[length - 1].id
				this.$scrollTo(`#${bottomItemId}`, 100, { container: this.tableContainer })
			} catch (e) { }
		},
		getHeaders () {
			const headers = this.fields
				.filter(f => f.hasOwnProperty('label') && !f.expanded && (f.value !== 'select' || (this.showSelect && f.value === 'select')))
				.map(f => {
					switch (f.value) {
						case 'actions':
						case 'select':
							break
						default:
							if (f.hasOwnProperty('id')) {
								f.value = this.fieldNames[f.id].replace('Id', '')
							}
							break
					}
					return {
						...f,
						text: f.label,
						sortable: !!f.sortable,
						sortIcon: 'mdi-unfold-more-horizontal',
						groupable: !!f.groupable,
						width: f.width || '',
						filterable: !f.hasOwnProperty('filterable') || f.filterable,
						grouped: false,
						isCurrency: f.isCurrency,
						isDate: f.isDate,
						isDateTime: f.isDateTime,
						selectAll: !!f.selectAll,
						copyId: f.copyId,
						route: !!f.route
					}
				})
			if (this.expanded) {
				headers.push({
					text: this.expandedHeader.text,
					value: 'data-table-expand',
					type: 'expand',
					width: this.expandedHeader.width
				})
			}
			if (this.showSelect) {
				headers.splice(0, 0, {
					label: '',
					value: 'select',
					width: '2%',
					selectAll: true
				})
			}
			this.headers = headers
		},
		myGroup (header, group) {
			this.setLoading(true, `Grouping ${this.title} by ${header.text}...`)

			setTimeout(() => {
				group(header.value)
				this.headers.forEach(h => {
					h.grouped = h.value === header.value
				})
				this.isGroupingActive = true
				this.currentGroup = header.value
				this.setLoading(false)
			})
			setTimeout(() => {
				this.onIntersect()
			})
		},
		mySort (header, sort) {
			this.setLoading(true, `Sorting ${this.title} by ${header.text}...`)

			setTimeout(() => {
				sort(header.value)
				// reset sort icon
				this.headers.map(h => {
					if (h.value === header.value) {
						switch (h.sortIcon) {
							case 'mdi-menu-up':
								h.sortIcon = 'mdi-menu-down'
								break
							case 'mdi-menu-down':
								h.sortIcon = 'mdi-unfold-more-horizontal'
								break
							default:
								h.sortIcon = 'mdi-menu-up'
						}
					} else {
						h.sortIcon = 'mdi-unfold-more-horizontal'
					}
					return { ...h }
				})
				this.setLoading(false)
			})
		},
		toggleGroup (group) {
			if (!this.showGroupToggle) {
				return
			}
			const element = this.$refs[group]
			if (element && element.attributes) {
				const isGroupCell = element.attributes.hasOwnProperty('groupcell')
				if (isGroupCell) {
					if (element.attributes.hasOwnProperty('data-open') && this.allGroupsOpen) {
						element.children[0].children[0].click()
					} else if (!element.attributes.hasOwnProperty('data-open') && !this.allGroupsOpen) {
						element.children[0].children[0].click()
					}
				}
			}
		},
		toggleGroups (config = { silent: false }) {
			const silent = config.silent
			if (!this.showGroupToggle) {
				return
			}
			this.setLoading(true, 'Toggling groups...')

			setTimeout(() => {
				Object.keys(this.$refs).forEach(k => {
					this.toggleGroup(k)
				})
				if (!silent) {
					this.setLoading(false)
				}
				this.allGroupsOpen = !this.allGroupsOpen
			})
		},
		removeGroups () {
			const removeButton = this.$refs.removeGroups
			if (removeButton) {
				this.setLoading(true, 'Toggling groups...')

				setTimeout(() => {
					removeButton.$el.click()
					const header = this.headers.find(h => h.grouped)
					if (header) {
						header.grouped = false
					}
					if (!this.allGroupsOpen) {
						this.toggleGroups({ silent: true })
					}
					this.isGroupingActive = false
					this.currentGroup = null
					this.allGroupsOpen = true
					this.setLoading(false)
				})
			}
		},
		selectAll (event) {
			this.$emit('select-all', event)
		},
		groupSelect (payload) {
			this.$emit('group-select', payload)
		},
		sortByUpdatedAt () {
			this.removeGroups()
			this.sortBy = 'updatedAt'
			this.sortDesc = true
		},
		showFile (item) {
			const pdfWindow = window.open('')
			pdfWindow.document.write('<iframe width="100%" height="100%" src="data:application/pdf;base64, ' +
				encodeURI(item.ResumeFormData.data) + '"></iframe>')
		},
		actionButtons () {
			let btns = [{
				text: 'Edit',
				icon: 'mdi-pencil',
				color: 'green',
				click: (item) => {
					this.$emit('edit-item', item)
				}
			}, {
				text: 'Delete',
				icon: 'mdi-delete',
				click: (item) => {
					this.showDeleteConfirm({
						item,
						show: true
					})
				}
			}, {
				text: 'Confirm',
				icon: 'mdi-check',
				click: (item) => {
					this.showDeleteConfirm({
						item,
						show: false
					})
					this.$emit('delete-item', item)
				},
				color: 'green'
			}, {
				text: 'Cancel',
				icon: 'mdi-cancel',
				color: 'red darken-4',
				click: (item) => {
					this.showDeleteConfirm({
						item,
						show: false
					})
				}
			}]
			if (this.additionalActionButtons.length) {
				btns = this.additionalActionButtons.concat(btns)
			}
			return btns
		},
		showDeleteConfirm (config) {
			if (config.show) {
				this.$refs[`btn-Edit-${config.item[this.itemId]}`][0].$el.classList.add('d-none')
				this.$refs[`btn-Delete-${config.item[this.itemId]}`][0].$el.classList.add('d-none')
				this.$refs[`btn-Confirm-${config.item[this.itemId]}`][0].$el.classList.remove('d-none')
				this.$refs[`btn-Cancel-${config.item[this.itemId]}`][0].$el.classList.remove('d-none')
			} else {
				this.$refs[`btn-Edit-${config.item[this.itemId]}`][0].$el.classList.remove('d-none')
				this.$refs[`btn-Delete-${config.item[this.itemId]}`][0].$el.classList.remove('d-none')
				this.$refs[`btn-Confirm-${config.item[this.itemId]}`][0].$el.classList.add('d-none')
				this.$refs[`btn-Cancel-${config.item[this.itemId]}`][0].$el.classList.add('d-none')
			}
		},
		setCurrentItems (items) {
			const table = this.$refs[this.tableRef]
			if (table) {
				if (items.length === this.items.length) {
					this.setTextSearchItems([])
				} else {
					this.setTextSearchItems(table.$children[0].filteredItems.map(i => i[this.itemId]))
				}
			}
		},
		isGroupCheckboxIndeterminate (items) {
			return items.filter(i => i._selected).length < items.length &&
				items.filter(i => i._selected).length > 0
		},
		groupCheckboxValue (items) {
			return items.filter(i => i._selected).length === items.length
		},
		isGroupCheckboxDisabled (items) {
			return !items.length || (items.length && items[0].disabled)
		},
		groupDisplayText (items, group, groupBy) {
			if (groupBy[0] === 'dateGroup' && items[0].hasOwnProperty('dateGroupDisplay')) {
				return items[0].dateGroupDisplay
			} else {
				return group
			}
		},
		onUpdate () {
			setTimeout(() => {
				this.onIntersect()
			})
		},
		onToggle (toggle) {
			toggle()
			setTimeout(() => {
				this.onIntersect()
			})
		}
	}
}
</script>
<style lang="scss">
@use '../../styles/cm';

.crafts-master-table-container {
	$background-color: #78909c; // blue-grey lighten-1
	$table-background-color: white; /// #fafafa; // grey lighten-5
	$border-color: cm.$portal-tab-color;

	background-color: transparent !important;

	.group-color {
		background-color: $border-color;
	}

	.v-data-table__wrapper {
		overflow-x: hidden;
		background-color: $background-color;

		table {
			background-color: $table-background-color;
		}
	}
	tbody {
		tr td {
			border-top: solid 2px $border-color !important;
			border-bottom: solid 2px $border-color !important;
			&.group-column {
				background-color: #eeeeee; // grey lighten-3
			}
		}
		td:first-child {
			border-left: solid 4px $border-color !important;
		}
		td:last-child {
			border-right: solid 4px $border-color !important;
		}
		tr:first-child td {
			border-top: solid 4px $border-color !important;
		}
		tr:last-child td {
			border-bottom: solid 4px $border-color !important;
		}
	}

	thead tr {
		th {
			@include cm.cell-format();
		}
		th.size-small {
			@include cm.cell-format($font-size: 0.85rem);
		}
	}

	tr {
		td {
			@include cm.cell-format($font-size: 0.85rem, $padding: 10px);
			border-left: solid 1px #aaa;
		}
		td[groupcell] {
			@include cm.cell-format();
			height: 44px !important;
		}
	}

	.v-input--selection-controls__input {
		margin-right: 0;
	}

	.highlight-selected {
		background-color: #fff176 !important; // yellow lighten-2
	}
	.button-key {
		position: absolute;
		margin-left: -85px;
		margin-top: -4px;
	}
}
</style>
<style lang="scss" scoped>
@use '../../styles/cm';

$font-size: 55vw;

.background-image {
	position: fixed;
	font-size: $font-size;
	top: calc(55vh - (#{$font-size} / 2) + (#{cm.$main-app-bar-height} / 2));
	right: calc(50vw - (#{$font-size} / 2) - (#{cm.$left-menu-icon-col-width} / 2));
	z-index: 10;
	opacity: 0.1;
	pointer-events: none;
}

.table-icon {
	font-size: 2.3vw;
}
</style>
