<template>
	<div v-if="config" class="d-flex align-end cm-text">
		<div
			@click="copyId(config.id)"
			class="yellow font-weight-medium rounded-br anchor-text animate__animated animate__slideInDown">
			{{ config.id }}
		</div>
		<div class="text-right pl-3 pr-1 py-1 animate__animated animate__fadeInUp white">
			<v-select
				v-if="config.gotoRoute"
				class="id-select"
				:value="selectedItem"
				@input="config.gotoRoute"
				dense hide-details
				:menu-props="{ offsetY: true }"
				return-object
				:item-value="config.valueField"
				:items="config.items">
				<template #selection="data">
					<div class="cm-text d-flex align-center text-right">
						<div>
							<div class="red--text text--darken-3 font-weight-medium">{{ data.item[config.titleField]}}</div>
							<div v-if="config.subtitleField" class="red--text text--darken-3 font-weight-light">{{ data.item[config.subtitleField] }}</div>
						</div>
					</div>
				</template>
				<template #item="data">
					<div class="row no-gutters text-caption" style="width: 400px">
						<div class="col" :class="`col-${config.titleFieldCols}`">{{ data.item[config.titleField] }}</div>
						<div v-if="config.subtitleField" class="col" :class="`col-${config.subtitleFieldCols}`">{{ data.item[config.subtitleField] }}</div>
						<div class="col">
							<div class="font-weight-bold">{{ data.item[config.valueField] }}</div>
							<div v-if="config.extraField" class="font-weight-light" style="line-height: 1">{{ data.item[config.extraField] }}</div>
						</div>
					</div>
				</template>
			</v-select>
			<div v-else class="d-flex align-center text-right">
				<div>
					<div class="red--text text--darken-3 font-weight-medium">{{ config.title }}</div>
					<div class="red--text text--darken-3 font-weight-light">{{ config.subtitle }}</div>
				</div>
			</div>
		</div>
		<div v-if="isVendorCraftsmanJobOrderHistoryView" :class="infoContainerClass">
			<div class="right-border text-left pr-3">
				<div>{{ currentCraftsman.Address }}</div>
				<div>{{ `${currentCraftsman.City}, ${currentCraftsman.State} ${currentCraftsman.Zip}` }}</div>
			</div>
			<div class="right-border text-left px-3">
				<div class="font-weight-medium">Phone: <span class="font-weight-light">{{ currentCraftsman.Phone }}</span></div>
				<div class="font-weight-medium">Email: <span class="font-weight-light">{{ currentCraftsman.Email }}</span></div>
			</div>
			<div class="text-right pl-3">
				<div class="font-weight-medium">Rate: <span class="font-weight-light">$ {{ parseFloat(currentCraftsman.Rate).toFixed(2).toString() }}</span></div>
			</div>
		</div>
		<div v-if="isEmployerCraftsmanJobOrderHistoryView" :class="infoContainerClass">
			<div class="d-flex right-border align-end">
				<div class="text-left" style="align-self: start">
					<div class="font-weight-medium">{{ vendor.Name }}:</div>
				</div>
				<div class="text-left pl-1 pr-3">
					<div class="font-weight-light">{{ vendor.Address }}</div>
					<div class="font-weight-light">{{ `${vendor.City}, ${vendor.State} ${vendor.Zip}` }}</div>
				</div>
				<div class="text-left px-3">
					<div class="font-weight-medium">Phone: <span class="font-weight-light">{{ vendor.Phone }}</span></div>
					<div class="font-weight-medium">Email: <span class="font-weight-light">{{ vendor.Email }}</span></div>
				</div>
			</div>
			<div class="text-left pl-3">
				<div class="font-weight-medium">Rate: <span class="font-weight-light">$ {{ parseFloat(currentCraftsman.Rate).toFixed(2).toString() }}</span></div>
			</div>
		</div>
		<div v-if="isJobOrderCraftsmenView" :class="infoContainerClass">
			<div v-if="isVendor" class="text-right">
				<div class="font-weight-medium">{{ employer.Name }}</div>
				<div class="font-weight-light">{{ yard.Name }}, {{ yardArea }}</div>
			</div>
			<div v-else class="text-left">
				<div class="font-weight-medium">{{ yard.Name }}</div>
				<div class="font-weight-medium">{{ yardArea }}</div>
			</div>
			<div class="right-border text-left px-3">
				<div class="font-weight-light">{{ yard.Address }}</div>
				<div class="font-weight-light">{{ `${yard.City}, ${yard.State} ${yard.Zip}` }}</div>
			</div>
			<div class="right-border text-right px-3">
				<div>
					<span class="font-weight-medium">Start: </span><span class="font-weight-light">{{ startDate }}</span>
				</div>
				<div>
					<span class="font-weight-medium">End: </span><span class="font-weight-light">{{ endDate }}</span>
				</div>
			</div>
			<div class="right-border text-right px-3">
				<div>
					<span class="font-weight-medium">Bill Rate: </span>
					<span class="font-weight-light">$ {{ billRate }}</span>
				</div>
				<div>
					<span class="font-weight-medium">Pay Rate: </span>
					<span class="font-weight-light">$ {{ parseFloat(currentJobOrder.PayRate).toFixed(2).toString() }}</span>
				</div>
			</div>
			<div class="text-left pl-3 pr-0">
				<div class="font-weight-medium">Craftsman</div>
				<div class="font-weight-medium">Count: <span class="font-weight-light">{{ currentJobOrder.CraftsmanCount }}</span></div>
			</div>
		</div>
	</div>
</template>
<script>
import { Portals, Portal, View } from '@/models/craftsMaster'
import JobOrder, { Crafts, Classes } from '@/models/jobOrder'
import { useCraftsmanStore } from '@/store/craftsmanStore'
import { useCraftsMasterStore } from '@/store/craftsMasterStore'
import { useJobOrderStore } from '@/store/jobOrderStore'
import { useUserStore } from '@/store/userStore'
import { useEmployerStore } from '@/store/employerStore'
import { useVendorStore } from '@/store/vendorStore'
import { mapState } from 'pinia'
import moment from 'moment'
import { copyId } from '@/components/shared/DataTableWrapper'

export default {
	data: () => ({
		JobOrder,
		Crafts,
		Classes,
		copyId,
		selectedItem: null,
		infoContainerClass: ['d-flex', 'px-3', 'pb-1', 'align-end', 'animate__animated', 'animate__fadeInUp']
	}),
	created () {
		if (this.config && this.config.items) {
			this.selectedItem = this.config.items.find(i => i[this.config.valueField] === this.config.id)
		}
	},
	watch: {
		config: {
			handler (val) {
				if (val && val.items) {
					this.selectedItem = val.items.find(i => i[val.valueField] === val.id)
				}
			},
			deep: true
		}
	},
	computed: {
		...mapState(useCraftsmanStore, [
			'currentCraftsman',
			'employerCraftsmen',
			'vendorCraftsmen'
		]),
		...mapState(useJobOrderStore, [
			'currentJobOrder',
			'employerJobOrders',
			'vendorJobOrders',
			'employerJobOrderCraftsmen'
		]),
		...mapState(useCraftsMasterStore, ['currentTab']),
		...mapState(useUserStore, [
			'portal',
			'isVendor',
			'isEmployer',
			'userByCompanyId'
		]),
		...mapState(useEmployerStore, ['employerById']),
		...mapState(useVendorStore, ['vendorById']),
		config () {
			let config = null
			if (this.isVendorCraftsmanJobOrderHistoryView || this.isEmployerCraftsmanJobOrderHistoryView) {
				config = {
					id: this.currentCraftsman.CraftsmanId,
					valueField: 'CraftsmanId',
					titleField: 'Name',
					subtitleField: 'Vendor',
					titleFieldCols: 4,
					subtitleFieldCols: 5,
					items: this.craftsmanItems,
					gotoRoute: (selectedItem) => {
						if (selectedItem) {
							this.$router.push({
								name: Portals[this.portal.id].tabs[View[`${this.isEmployer ? 'Employer' : 'Vendor'}CraftsmanJobOrderHistory`]].name,
								params: {
									craftsmanId: selectedItem.CraftsmanId
								}
							})
						}
					}
				}
			} else if (this.isJobOrderCraftsmenView) {
				config = {
					id: this.currentJobOrder.JobOrderId,
					valueField: 'JobOrderId',
					titleField: 'Craft',
					subtitleField: 'Class',
					extraField: 'isInactiveText',
					titleFieldCols: 7,
					subtitleFieldCols: 2,
					items: this.jobOrderItems,
					gotoRoute: (selectedItem) => {
						if (selectedItem) {
							this.$router.push({
								name: Portals[this.portal.id].tabs[View[`${this.isEmployer ? 'Employer' : 'Vendor'}JobOrderCraftsmen`]].name,
								params: {
									jobOrderId: selectedItem.JobOrderId
								}
							})
						}
					}
				}
			}
			return config
		},
		isVendorCraftsmanJobOrderHistoryView () {
			return this.currentCraftsman &&
				(this.portal.id === Portal.Vendor && this.currentTab.id === View.VendorCraftsmanJobOrderHistory)
		},
		isEmployerCraftsmanJobOrderHistoryView () {
			return this.currentCraftsman &&
				(this.portal.id === Portal.Employer && this.currentTab.id === View.EmployerCraftsmanJobOrderHistory)
		},
		isJobOrderCraftsmenView () {
			return this.currentJobOrder &&
				((this.portal.id === Portal.Vendor && this.currentTab.id === View.VendorJobOrderCraftsmen) ||
					(this.portal.id === Portal.Employer && this.currentTab.id === View.EmployerJobOrderCraftsmen))
		},
		craftsmanItems () {
			let craftsmanItems = []
			if (this.isEmployer) {
				craftsmanItems = this.employerCraftsmen.slice()
					.sort((a, b) => a.Name.localeCompare(b.Name))
			} else {
				craftsmanItems = this.vendorCraftsmen.slice()
					.sort((a, b) => a.Name.localeCompare(b.Name))
			}
			return craftsmanItems
		},
		jobOrderItems () {
			let jobOrderItems = []
			if (this.isEmployer) {
				jobOrderItems = this.employerJobOrders
					.filter(j => j.statusId === JobOrder.Status.Submitted)
					.sort((a, b) => a.JobOrderId.localeCompare(b.JobOrderId))
			} else {
				jobOrderItems = this.vendorJobOrders.slice()
					.sort((a, b) => a.JobOrderId.localeCompare(b.JobOrderId))
			}
			return jobOrderItems
		},
		employer () {
			return this.employerById(this.currentJobOrder.EmployerId)
		},
		vendor () {
			const vendor = this.vendorById(this.currentCraftsman.VendorId)
			if (vendor) {
				const vendorUser = this.userByCompanyId(vendor.VendorId)
				const { Phone, Email } = { ...vendorUser }
				return { ...vendor, Phone, Email }
			} else {
				return {}
			}
		},
		yard () {
			const yardAreaId = this.currentJobOrder.YardAreaId.split('.')
			return this.employer.Yards.find(y => y.YardId === yardAreaId[0])
		},
		yardArea () {
			const yardAreaId = this.currentJobOrder.YardAreaId.split('.')
			return this.yard.Areas[yardAreaId[1]]
		},
		startDate () {
			return this.currentJobOrder.StartDate ? moment(this.currentJobOrder.StartDate).utc().format('ddd, MMM D YYYY') : 'Unknown'
		},
		endDate () {
			return this.currentJobOrder.EndDate ? moment(this.currentJobOrder.EndDate).utc().format('ddd, MMM D YYYY') : 'Unknown'
		},
		billRate () {
			return this.currentJobOrder.BillRateLo === this.currentJobOrder.BillRateHi
				? this.currentJobOrder.BillRateLo
				: `${parseFloat(this.currentJobOrder.BillRateLo).toFixed(2).toString()} - ${parseFloat(this.currentJobOrder.BillRateHi).toFixed(2).toString()}`
		}
	}
}
</script>
<style lang="scss">
.id-select {
	.v-select__slot {
		input[type='text'] {
			display: none;
		}
	}
}
</style>
<style lang="scss" scoped>
@use '../styles/cm';

.anchor-text {
	position: absolute;
	cursor: pointer;
	top: 0px;
	left: cm.$left-menu-icon-col-width;
	font-size: 11px;
	padding: 2px 5px;
}
</style>
