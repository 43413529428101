import { http } from '@/utils'
// import Chance from 'chance'
// import { useCraftsMasterStore } from '@/store/craftsMasterStore'
// import { useVendorStore } from '@/store/vendorStore'
// import { useCraftsmanStore } from '@/store/craftsmanStore'
// import { useEmployerStore } from '@/store/employerStore'
// import Craftsman from '@/common/craftsman'
// import CraftsMaster, { TestDataLimit } from '@/common/craftsMaster'
// import moment from 'moment'

// const chance = Chance()

// const JobOrdersKey = 'craftsmaster:joborders'

// const getTestJobOrder = (config) => {
// config = config || {}

// const craftsMasterStore = useCraftsMasterStore()

// const JobOrderId = chance.hash({ length: 15 })
// const CraftId = chance.integer({ min: 0, max: 18 })
// const ClassId = chance.integer({ min: 0, max: 2 })

// // add vendors
// const Vendors = []
// let vendorIndices = []

// if (!config.noVendors) {
// 	const vendorStore = useVendorStore()
// 	const storeVendors = vendorStore.vendors

// 	vendorIndices = chance.unique(
// 		chance.integer, // what to retrieve
// 		chance.integer({
// 			min: craftsMasterStore.testDataLimits[TestDataLimit.VendorsPerJobOrder].range[0],
// 			max: craftsMasterStore.testDataLimits[TestDataLimit.VendorsPerJobOrder].range[1]
// 		}), // how many to retrieve
// 		{ min: 0, max: storeVendors.length - 1 } // min/max values to choose from
// 	)

// 	vendorIndices.forEach(index => {
// 		Vendors.push({
// 			JobOrderId,
// 			VendorId: storeVendors[index].VendorId,
// 			value: storeVendors[index].VendorId,
// 			text: storeVendors[index].Name
// 		})
// 	})
// }

// const Craftsmen = []

// // add craftsmen from previously added vendors
// if (Vendors.length) {
// 	const craftsmanStore = useCraftsmanStore()
// 	const storeCraftsmen = craftsmanStore.craftsmen

// 	// add some qualifying craftsmen from each vendor
// 	Vendors.forEach(v => {
// 		const qualifyingCraftsmen = storeCraftsmen
// 			.filter(c => {
// 				return c.VendorId === v.VendorId &&
// 					c.ClassId === ClassId &&
// 					c.CraftId === CraftId
// 			})
// 			.map(c => ({
// 				CraftsmanId: c.CraftsmanId,
// 				// below values duplicated to speed roll-up in JobOrder setStatus method
// 				// and in craftsman data tables
// 				JobOrderId,
// 				VendorId: v.VendorId,
// 				StatusId: Craftsman.Status.Available
// 			}))

// 		// leave some qualifying craftsmen out
// 		if (qualifyingCraftsmen.length) {
// 			const amount = Math.ceil(qualifyingCraftsmen.length * 0.8)

// 			qualifyingCraftsmen.slice(0, amount).forEach(c => {
// 				Craftsmen.push(c)
// 			})
// 		}
// 	})

// 	// set some craftsman statuses on above added craftsmen
// 	const inProgressWeight = chance.integer({
// 		min: craftsMasterStore.testDataLimits[TestDataLimit.InProgressJobOrders].range[0],
// 		max: craftsMasterStore.testDataLimits[TestDataLimit.InProgressJobOrders].range[1]
// 	})
// 	for (const c in Craftsmen) {
// 		c.StatusId = chance.weighted([
// 			Craftsman.Status.Submitted,
// 			Craftsman.Status.InterviewRequested,
// 			Craftsman.Status.InterviewScheduled,
// 			Craftsman.Status.OfferPending,
// 			Craftsman.Status.Accepted,
// 			Craftsman.Status.Onboarding,
// 			Craftsman.Status.OnAssignment,
// 			Craftsman.Status.CompletedAssignment,
// 			Craftsman.Status.LeftAssignment,
// 			Craftsman.Status.Terminated,
// 			Craftsman.Status.Rejected
// 		], [inProgressWeight, 5, 2, 2, 2, 2, 2, 2, 2, 1, 1, 1])

// 		// await craftsmanStore.updateCraftsmanStatus_StoreOnly({
// 		// 	craftsmanId: c.CraftsmanId,
// 		// 	status: c.StatusId
// 		// })
// 	}
// }

// const employerStore = useEmployerStore()
// const storeEmployers = employerStore.employers
// const employerIndex = chance.integer({ min: 0, max: storeEmployers.length - 1 })

// const startDate = moment(chance.date({ month: moment().month() + 1, year: moment().year() }))
// const endDate = moment(startDate).add(chance.integer({ min: 1, max: 4 }), 'months')

// const yardAreas = ['Warehouse', 'Dock', 'Wharf', 'Workshop', 'Port']
// const YardArea = `${chance.pickone(yardAreas)} ${(chance.bool() ? chance.word().toUpperCase() : chance.integer({ min: 1, max: 7 }))}`
// const YardName = `${chance.last()} Shipyard`
// const BillRateLo = chance.floating({ min: 15, max: 50, fixed: 2 })

// const jobOrder = {
// 	CraftId,
// 	ClassId,
// 	YardName,
// 	YardArea,
// 	Posted: false,
// 	Address: chance.address(),
// 	City: chance.city(),
// 	State: chance.state(),
// 	Zip: chance.zip(),
// 	StartDate: startDate.format('YYYY-MM-DD'),
// 	EndDate: endDate.format('YYYY-MM-DD'),
// 	CraftsmanCount: chance.integer({
// 		min: craftsMasterStore.testDataLimits[TestDataLimit.CraftsmanCountPerJobOrder].range[0],
// 		max: craftsMasterStore.testDataLimits[TestDataLimit.CraftsmanCountPerJobOrder].range[1]
// 	}),
// 	BillRateLo,
// 	BillRateHi: BillRateLo + chance.integer({ min: 1, max: 10 }),
// 	PayRate: chance.floating({ min: 15, max: 50, fixed: 2 }),
// 	NonLocalCandidates: chance.bool(),
// 	Vendors,
// 	Notes: chance.paragraph({ sentences: 1 }),
// 	JobOrderId,
// 	Craftsmen,
// 	EmployerId: storeEmployers[employerIndex].EmployerId,
// 	_copied: false
// }

// return jobOrder
// }

// const getTestJobOrders = () => {
// return new Promise((resolve, reject) => {
// 	const date = new Date()
// 	let jobOrders = localStorage.getItem(JobOrdersKey)
// 	if (jobOrders) {
// 		jobOrders = JSON.parse(jobOrders)
// 	} else {
// 		jobOrders = []

// 		const craftsMasterStore = useCraftsMasterStore()
// 		const jobOrderCount = chance.integer({
// 			min: craftsMasterStore.testDataLimits[TestDataLimit.JobOrders].range[0],
// 			max: craftsMasterStore.testDataLimits[TestDataLimit.JobOrders].range[1]
// 		})
// 		for (let i = 0; i < jobOrderCount; i++) {
// 			jobOrders.push(getTestJobOrder())
// 		}
// 		localStorage.setItem(JobOrdersKey, JSON.stringify(jobOrders))

// 		// whilst cycling thru job order vendors during the creation of a test job order,
// 		// the craftsmen store object was modified, necessitating a refresh of the
// 		// craftsmen local storage object from the craftsman store
// 		const craftsmanStore = useCraftsmanStore()
// 		const craftsmen = craftsmanStore.craftsmen
// 		localStorage.setItem('craftsmaster:craftsmen', JSON.stringify(craftsmen))
// 	}
// 	console.log(`jobOrders: ${jobOrders.length} in ${new Date() - date} ms`)
// 	resolve({ data: jobOrders })
// 	reject(new Error('JobOrderDataService.getTestJobOrders: Error'))
// })
// }
export default {
	getAll () {
		return http.get('/jobOrders')
	},
	getById (id) {
		return http.get(`/jobOrders/${id}`)
	},
	create (data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		const jobOrder = Object.assign({}, data)
		// 		jobOrder.JobOrderId = chance.hash({ length: 5 })
		// 		jobOrder._copied = false
		// 		let jobOrders = localStorage.getItem(JobOrdersKey)
		// 		if (jobOrders) {
		// 			jobOrders = JSON.parse(jobOrders)
		// 			jobOrders.push(jobOrder)
		// 			localStorage.setItem(JobOrdersKey, JSON.stringify(jobOrders))
		// 			resolve({ data: jobOrder })
		// 		} else {
		// 			reject(new Error('JobOrderDataService.create: no job orders found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.post('/jobOrders', data)
		// }
	},
	update (id, data) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		let jobOrders = localStorage.getItem(JobOrdersKey)
		// 		if (jobOrders) {
		// 			jobOrders = JSON.parse(jobOrders)
		// 			const jobOrder = jobOrders.find(j => j.JobOrderId === id)
		// 			if (jobOrder) {
		// 				Object.assign(jobOrder, data)
		// 				localStorage.setItem(JobOrdersKey, JSON.stringify(jobOrders))
		// 				resolve({ data: jobOrder })
		// 			} else {
		// 				reject(new Error(`JobOrderDataService.update: job order ${id} not found in localStorage`))
		// 			}
		// 		} else {
		// 			reject(new Error('JobOrderDataService.update: no job orders found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.put(`/jobOrders/${id}`, data)
		// }
	},
	delete (id) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return new Promise((resolve, reject) => {
		// 		let jobOrders = localStorage.getItem(JobOrdersKey)
		// 		if (jobOrders) {
		// 			jobOrders = JSON.parse(jobOrders)
		// 			const jobOrderIndex = jobOrders.findIndex(j => j.JobOrderId === id)
		// 			if (jobOrderIndex !== -1) {
		// 				jobOrders.splice(jobOrderIndex, 1)
		// 				localStorage.setItem(JobOrdersKey, JSON.stringify(jobOrders))
		// 				resolve()
		// 			} else {
		// 				reject(new Error(`JobOrderDataService.update: job order ${id} not found in localStorage`))
		// 			}
		// 		} else {
		// 			reject(new Error('JobOrderDataService.update: no job orders found in localStorage'))
		// 		}
		// 	})
		// } else {
		return http.delete(`/jobOrders/${id}`)
		// }
	},
	addVendor (payload) {
		return http.put(`/jobOrders/addVendor/${payload.jobOrderId}`, payload.vendor)
	},
	postJobOrder (jobOrderId, payload) {
		return http.put(`/jobOrders/post/${jobOrderId}`, payload)
	},
	submitCraftsmen (jobOrderId, vendorId) {
		return http.put(`/jobOrders/submit/${jobOrderId}/${vendorId}`)
	},
	updateCraftsmen (payload) {
		return http.put(`/jobOrders/craftsmen/${payload.jobOrderId}`, payload)
	},
	deleteAll () {
		return http.delete('/jobOrders')
	},
	getByEmployerId (id) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return getTestJobOrders()
		// } else {
		return http.get(`/jobOrders/employer/${id}`)
		// }
	},
	getByVendorId (id) {
		// if (CraftsMaster.DataSource === 'test') {
		// 	return getTestJobOrders()
		// } else {
		return http.get(`/jobOrders/vendor/${id}`)
		// }
	},
	// getTestJobOrder () {
	// 	return new Promise((resolve) => {
	// 		resolve({
	// 			data: getTestJobOrder({
	// 				noVendors: true,
	// 				currentDate: true
	// 			})
	// 		})
	// 	})
	// }
	updateJobOrderIds (ids) {
		return http.patch('/jobOrders/updateJobOrderIds', ids)
	}
}
