import Vue from 'vue'
import { createPinia } from 'pinia'
import VueRouter from 'vue-router'
import { Portals, Portal, View } from '@/models/craftsMaster'
import { useUserStore } from '@/store/userStore'
import { useCraftsMasterStore } from '@/store/craftsMasterStore'

// don't like this, but router needs the store to reroute to login when no user is found,
// and loading up pinia in main.js happens after the router needs it
export const pinia = createPinia()
Vue.use(pinia)

Vue.use(VueRouter)

// for readability
const EmployerPortal = Portals[Portal.Employer]
const AdminPortal = Portals[Portal.Admin]
const VendorPortal = Portals[Portal.Vendor]

const routes = [
	{
		path: '/',
		name: 'login',
		component: () => import('../components/Login.vue')
	}, {
		path: EmployerPortal.path,
		name: EmployerPortal.name,
		component: () => import('../components/PortalContainer.vue'),
		children: [
			{
				path: EmployerPortal.tabs[View.EmployerDashboard].path,
				name: EmployerPortal.tabs[View.EmployerDashboard].name,
				meta: { title: 'Employer - Dashboard' },
				components: {
					default: () => import('../components/Portals/Employer/views/EmployerDashboard.vue')
				}
			}, {
				path: EmployerPortal.tabs[View.EmployerJobOrders].path,
				name: EmployerPortal.tabs[View.EmployerJobOrders].name,
				meta: { title: 'Employer - Job Orders' },
				components: {
					default: () => import('../components/Portals/Employer/views/EmployerJobOrders.vue'),
					'left-menu': () => import('../components/Portals/Employer/leftMenus/EmployerJobOrdersLeftMenu.vue')
				}
			}, {
				path: EmployerPortal.tabs[View.EmployerCraftsmen].path,
				name: EmployerPortal.tabs[View.EmployerCraftsmen].name,
				meta: { title: 'Employer - Craftsmen' },
				components: {
					default: () => import('../components/Portals/Employer/views/EmployerCraftsmen.vue'),
					'left-menu': () => import('../components/Portals/Employer/leftMenus/EmployerCraftsmenLeftMenu.vue')
				}
			}, {
				path: EmployerPortal.tabs[View.EmployerJobOrderCraftsmen].path,
				name: EmployerPortal.tabs[View.EmployerJobOrderCraftsmen].name,
				meta: { title: 'Employer - Job Order' },
				components: {
					default: () => import('../components/Portals/Employer/views/EmployerJobOrderCraftsmen.vue'),
					'left-menu': () => import('../components/Portals/Employer/leftMenus/EmployerJobOrderCraftsmenLeftMenu.vue')
				}
			}, {
				path: EmployerPortal.tabs[View.EmployerCraftsmanJobOrderHistory].path,
				name: EmployerPortal.tabs[View.EmployerCraftsmanJobOrderHistory].name,
				meta: { title: 'Employer - Craftsman' },
				components: {
					default: () => import('../components/Portals/Employer/views/EmployerCraftsmanJobOrderHistory.vue'),
					'left-menu': () => import('../components/Portals/Employer/leftMenus/EmployerCraftsmanJobOrderHistoryLeftMenu.vue')
				}
			}, {
				path: EmployerPortal.tabs[View.EmployerEvents].path,
				name: EmployerPortal.tabs[View.EmployerEvents].name,
				meta: { title: 'Employer - Events' },
				components: {
					default: () => import('../components/Portals/Employer/views/EmployerEvents.vue'),
					'left-menu': () => import('../components/Portals/Employer/leftMenus/EmployerEventsLeftMenu.vue')
				}
				// }, {
				// 	path: EmployerPortal.tabs[View.EmployerYards].path,
				// 	name: EmployerPortal.tabs[View.EmployerYards].name,
				// 	meta: { title: 'Employer - Yards' },
				// 	components: {
				// 		default: () => import('../components/Portals/Employer/views/EmployerYards.vue'),
				// 		'left-menu': () => import('../components/Portals/Employer/leftMenus/EmployerYardsLeftMenu.vue')
				// 	}
			}, {
				path: EmployerPortal.tabs[View.EmployerNotices].path,
				name: EmployerPortal.tabs[View.EmployerNotices].name,
				meta: { title: 'Employer - Notices' },
				components: {
					default: () => import('../components/shared/NoticeCenter.vue'),
					'left-menu': () => import('../components/shared/NoticeCenterLeftMenu.vue')
				}
			}
		]
	}, {
		path: AdminPortal.path,
		name: AdminPortal.name,
		component: () => import('../components/PortalContainer.vue'),
		children: [
			{
				path: AdminPortal.tabs[View.AdminEmployers].path,
				name: AdminPortal.tabs[View.AdminEmployers].name,
				components: {
					default: () => import('../components/Portals/Admin/views/AdminEmployers.vue'),
					'left-menu': () => import('../components/Portals/Admin/leftMenus/AdminEmployersLeftMenu.vue')
				}
			}, {
				path: AdminPortal.tabs[View.AdminVendors].path,
				name: AdminPortal.tabs[View.AdminVendors].name,
				components: {
					default: () => import('../components/Portals/Admin/views/AdminVendors.vue'),
					'left-menu': () => import('../components/Portals/Admin/leftMenus/AdminVendorsLeftMenu.vue')
				}
			}, {
				path: AdminPortal.tabs[View.AdminCraftsmen].path,
				name: AdminPortal.tabs[View.AdminCraftsmen].name,
				components: {
					default: () => import('../components/Portals/Admin/views/AdminCraftsmen.vue'),
					'left-menu': () => import('../components/Portals/Admin/leftMenus/AdminCraftsmenLeftMenu.vue')
				}
			}, {
				path: AdminPortal.tabs[View.AdminUsers].path,
				name: AdminPortal.tabs[View.AdminUsers].name,
				components: {
					default: () => import('../components/Portals/Admin/views/AdminUsers.vue'),
					'left-menu': () => import('../components/Portals/Admin/leftMenus/AdminUsersLeftMenu.vue')
				}
			}, {
				path: AdminPortal.tabs[View.AdminJobOrders].path,
				name: AdminPortal.tabs[View.AdminJobOrders].name,
				components: {
					default: () => import('../components/Portals/Admin/views/AdminJobOrders.vue'),
					'left-menu': () => import('../components/Portals/Admin/leftMenus/AdminJobOrdersLeftMenu.vue')
				}
			}, {
				path: AdminPortal.tabs[View.AdminEvents].path,
				name: AdminPortal.tabs[View.AdminEvents].name,
				components: {
					default: () => import('../components/Portals/Admin/views/AdminEvents.vue'),
					'left-menu': () => import('../components/Portals/Admin/leftMenus/AdminEventsLeftMenu.vue')
				}
			}, {
				path: AdminPortal.tabs[View.AdminYards].path,
				name: AdminPortal.tabs[View.AdminYards].name,
				components: {
					default: () => import('../components/Portals/Admin/views/AdminYards.vue'),
					'left-menu': () => import('../components/Portals/Admin/leftMenus/AdminYardsLeftMenu.vue')
				}
			}, {
				path: AdminPortal.tabs[View.AdminNotices].path,
				name: AdminPortal.tabs[View.AdminNotices].name,
				components: {
					default: () => import('../components/shared/NoticeCenter.vue'),
					'left-menu': () => import('../components/shared/NoticeCenterLeftMenu.vue')
				}
			}
		]
	}, {
		path: VendorPortal.path,
		name: VendorPortal.name,
		component: () => import('../components/PortalContainer.vue'),
		children: [
			{
				path: VendorPortal.tabs[View.VendorJobOrders].path,
				name: VendorPortal.tabs[View.VendorJobOrders].name,
				meta: { title: 'Vendor - Job Orders' },
				components: {
					default: () => import('../components/Portals/Vendor/views/VendorJobOrders.vue'),
					'left-menu': () => import('../components/Portals/Vendor/leftMenus/VendorJobOrdersLeftMenu.vue')
				}
			}, {
				path: VendorPortal.tabs[View.VendorCraftsmen].path,
				name: VendorPortal.tabs[View.VendorCraftsmen].name,
				meta: { title: 'Vendor - Craftsmen' },
				components: {
					default: () => import('../components/Portals/Vendor/views/VendorCraftsmen.vue'),
					'left-menu': () => import('../components/Portals/Vendor/leftMenus/VendorCraftsmenLeftMenu.vue')
				}
			}, {
				path: VendorPortal.tabs[View.VendorJobOrderCraftsmen].path,
				name: VendorPortal.tabs[View.VendorJobOrderCraftsmen].name,
				meta: { title: 'Vendor - Job Order' },
				components: {
					default: () => import('../components/Portals/Vendor/views/VendorJobOrderCraftsmen.vue'),
					'left-menu': () => import('../components/Portals/Vendor/leftMenus/VendorJobOrderCraftsmenLeftMenu.vue')
				}
			}, {
				path: VendorPortal.tabs[View.VendorCraftsmanJobOrderHistory].path,
				name: VendorPortal.tabs[View.VendorCraftsmanJobOrderHistory].name,
				meta: { title: 'Vendor - Craftsman' },
				components: {
					default: () => import('../components/Portals/Vendor/views/VendorCraftsmanJobOrderHistory.vue'),
					'left-menu': () => import('../components/Portals/Vendor/leftMenus/VendorCraftsmanJobOrderHistoryLeftMenu.vue')
				}
			}, {
				path: VendorPortal.tabs[View.VendorEvents].path,
				name: VendorPortal.tabs[View.VendorEvents].name,
				meta: { title: 'Vendor - Events' },
				components: {
					default: () => import('../components/Portals/Vendor/views/VendorEvents.vue'),
					'left-menu': () => import('../components/Portals/Vendor/leftMenus/VendorEventsLeftMenu.vue')
				}
			}, {
				path: VendorPortal.tabs[View.VendorNotices].path,
				name: VendorPortal.tabs[View.VendorNotices].name,
				meta: { title: 'Vendor - Notices' },
				components: {
					default: () => import('../components/shared/NoticeCenter.vue'),
					'left-menu': () => import('../components/shared/NoticeCenterLeftMenu.vue')
				}
			}
		]
	}
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const craftsMasterStore = useCraftsMasterStore()
	craftsMasterStore.setRouteFrom(from)

	if (useUserStore().currentUserId ||
		to.name === 'login') {
		next()
	} else {
		craftsMasterStore.setRoute(to)
		next({
			name: 'login',
			params: to.params
		})
	}

	Vue.nextTick(() => {
		document.title = to.meta.title || ''
	})
})

// router.afterEach(() => {
// 	console.log('setNavigationComplete: true')
// 	store.dispatch('setNavigationComplete', true)
// })

export default router
