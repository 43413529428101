import { defineStore } from 'pinia'
import EventDataService from '@/services/EventDataService'
import { useCraftsMasterStore } from './craftsMasterStore'
import { useJobOrderStore } from './jobOrderStore'
import { useVendorStore } from './vendorStore'
import { useEmployerStore } from './employerStore'
import { useCraftsmanStore } from './craftsmanStore'
import { useUserStore } from './userStore'
import { useNoticeStore } from './noticeStore'
import { View } from '@/models/craftsMaster'
import Chance from 'chance'
import Vue from 'vue'
import Event from '@/models/event'
import JobOrder from '@/models/jobOrder'
import Craftsman from '@/models/craftsman'
import moment from 'moment'

const chance = Chance()

async function addEventNotice ({ event, actionText }) {
	let Notes = `<div>Event ${actionText}: ${Event.Types[event.TypeId].text} (${Event.Statuses[event.StatusId].text})</div>`
	Notes += `<div>Start: <span class="font-weight-bold">${moment(event.Start).format('ddd MMM D, YYYY h:mm a')}</span></div>`
	Notes += `<div>End: <span class="font-weight-bold">${moment(event.End).format('ddd MMM D, YYYY h:mm a')}</span></div>`

	await useNoticeStore().addNotice({
		EventId: event.EventId,
		JobOrderId: event.JobOrderId,
		CraftsmanId: event.CraftsmanId,
		Notes
	})
}

export const useEventStore = defineStore('event', {
	state: () => ({
		events: [],
		calendar: {
			focus: '',
			type: 'month'
		},
		currentCaledarEvent: null,
		eventTypeFilter: [],
		eventStatusFilter: [],
		showCurrentJobOrder: false,
		showCurrentCraftsman: false
	}),
	getters: {
		eventById: state => id => {
			const event = state.events.find(e => e.EventId === id)
			return event ? { ...event } : null
		},
		eventsTableView (state) {
			const craftsmanStore = useCraftsmanStore()
			const jobOrderStore = useJobOrderStore()
			const vendorStore = useVendorStore()
			const employerStore = useEmployerStore()
			const userStore = useUserStore()
			const jobOrderView = userStore.portal.tabs[userStore.isEmployer ? View.EmployerJobOrderCraftsmen : View.VendorJobOrderCraftsmen]
			const eventView = userStore.portal.tabs[userStore.isEmployer ? View.EmployerEvents : View.VendorEvents]
			const events = state.events
				.filter(e => e.TypeId !== Event.Type.JobOrder)
				.map(e => {
					const craftsman = craftsmanStore.craftsmanById(e.CraftsmanId)
					const jobOrder = jobOrderStore.jobOrderById(e.JobOrderId)
					const creator = userStore.companyById(e.CreatorId)
					// ensure craftsman is on job order
					if (jobOrder && jobOrder.Craftsmen && jobOrder.Craftsmen.findIndex(joc => joc.CraftsmanId === craftsman?.CraftsmanId) !== -1) {
						const employer = employerStore.employerById(jobOrder.EmployerId)
						const vendor = vendorStore.vendorById(craftsman?.VendorId)
						if (vendor) {
							const start = moment(e.Start)
							return {
								...e,
								Creator: creator.name,
								Type: Event.Types[e.TypeId].text,
								Status: Event.Statuses[e.StatusId].text,
								Craftsman: craftsman.Name,
								JobOrder: JobOrder.getJobTitle(jobOrder).craftClassJobOrderId,
								craftsmanName: craftsman.Name,
								vendorName: vendor.Name,
								VendorId: vendor.VendorId,
								employerName: employer.Name,
								EmployerId: jobOrder.EmployerId,
								dateDisplay: start.format('MMM D, YYYY'),
								dateGroup: start.format('YYYYMM'),
								dateGroupDisplay: start.format('MMMM YYYY'),
								timeDisplay: start.format('h:mm a'),
								endDate: moment(e.End).format('MMM D, YYYY'),
								endTime: moment(e.End).format('h:mm a'),
								jobOrderName: JobOrder.getJobTitle(jobOrder).craftClassJobOrderId,
								jobOrderRoute: {
									name: jobOrderView.name,
									params: {
										companyId: userStore.isEmployer ? jobOrder.EmployerId : vendor.VendorId,
										jobOrderId: e.JobOrderId
									}
								},
								eventRoute: {
									name: eventView.name
								}
							}
						} else {
							return null
						}
					} else {
						return null
					}
				})
			return events.filter(e => !!e)
		}
	},
	actions: {
		setCalendarFocus (date) {
			this.calendar.focus = date
		},
		setCalendarType (type) {
			this.calendar.type = type
		},
		setCurrentCalendarEvent (event) {
			this.currentCalendarEvent = Object.assign({}, event)
		},
		getUpcomingJocEvents (payload) {
			const { CraftsmanId, JobOrderId, eventStatusId = Event.Status.Pending } = payload
			// eventStatusId === null means don't consider Status Id but return any
			// upcoming event for given Craftsman Id and Job Order Id
			const events = this.eventsTableView
				.filter(e => {
					return !Event.isPast(e) &&
						(eventStatusId === null || e.StatusId === eventStatusId) &&
						e.CraftsmanId === CraftsmanId &&
						e.JobOrderId === JobOrderId
				})
				.sort((a, b) => moment(b.Start).isBefore(a.Start) ? 1 : -1)

			return events
		},
		resetCalendar () {
			this.calendar = {
				focus: '',
				type: 'month'
			}
		},
		clearEvents (ids) {
			const events = []
			if (ids) {
				this.events.forEach(e => {
					if (!ids.includes(e.EventId)) {
						events.push(e)
					}
				})
			}
			this.events = events
		},
		async getEvents () {
			try {
				const response = await EventDataService.getAll()
				if (response) {
					this.events = response.data
				}
			} catch (error) {
				this.events = []

				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.response.data.message,
					type: 'error'
				})
			}
		},
		async getEventById (id) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await EventDataService.getById(id)
				if (response && response.data) {
					const index = this.events.findIndex(i => i.EventId === response.data.EventId)
					if (index === -1) {
						this.events.push(response.data)
					}
				}
			} catch (error) {
				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.response.data.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async saveEvent (event) {
			if (event.EventId) {
				await this.updateEvent(event)
			} else {
				await this.addEvent(event)
			}
		},
		async addEvent (e) {
			const event = Object.assign({}, e, {
				CreatorId: e.CreatorId || useUserStore().currentCompanyId
			})
			const silent = event.silent
			delete event.silent

			const craftsMasterStore = useCraftsMasterStore()
			if (!silent) {
				craftsMasterStore.loading = true
			}

			try {
				event.EventId = chance.hash({ length: 10 })
				const response = await EventDataService.create(event)
				if (response && response.data) {
					this.events.push(response.data)
					// await this.getEventsByCompanyId({ silent })

					await addEventNotice({
						event: response.data,
						actionText: 'added'
					})

					if (!silent) {
						craftsMasterStore.setSnackbar({
							value: true,
							text: `${Event.Types[response.data.TypeId].text} on (${moment(response.data.Start).format('ddd MMM D, YYYY h:mm a')}) added`
						})
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `${Event.Types[event.TypeId].text} on (${moment(event.Start).format('ddd MMM D, YYYY h:mm a')}) not added`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				if (!silent) {
					craftsMasterStore.loading = false
				}
			}
		},
		async updateEvent (event) {
			const silent = event.silent
			delete event.silent

			const craftsMasterStore = useCraftsMasterStore()

			if (!silent) {
				craftsMasterStore.loading = true
			}

			try {
				if (!event.CreatorId) {
					event.CreatorId = this.events.find(e => e.EventId === event.EventId)?.CreatorId
				}
				const response = await EventDataService.update(event.EventId, event)
				if (response && response.data) {
					const index = this.events.findIndex(e => e.EventId === response.data.EventId)
					if (index !== -1) {
						Vue.set(this.events, index, response.data)
						// await this.getEventsByCompanyId({ silent })

						await addEventNotice({
							event: response.data,
							actionText: 'updated'
						})

						if (!silent) {
							craftsMasterStore.setSnackbar({
								value: true,
								text: `${Event.Types[response.data.TypeId].text} on (${moment(response.data.Start).format('ddd MMM D, YYYY h:mm a')}) updated`
							})
						}
					}
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `${Event.Types[event.TypeId].text} on (${moment(event.Start).format('ddd MMM D, YYYY h:mm a')}) not updated`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.response.data.message
				})
			} finally {
				if (!silent) {
					craftsMasterStore.loading = false
				}
			}
		},
		async getEventsByCompanyId (payload = { silent: false }) {
			const { silent } = payload
			const userStore = useUserStore()

			if (userStore.currentCompanyId || userStore.isAdmin) {
				const craftsMasterStore = useCraftsMasterStore()

				if (!silent) {
					craftsMasterStore.loading = true
				}

				try {
					let response
					if (userStore.isEmployer) {
						response = await EventDataService.getByEmployerId(userStore.currentCompanyId)
					} else if (userStore.isVendor) {
						response = await EventDataService.getByVendorId(userStore.currentCompanyId)
					} else if (userStore.isAdmin) {
						response = await EventDataService.getAll()
					}
					if (response && response.data) {
						this.events = response.data
					} else {
						this.events = []
					}
				} catch (error) {
					this.events = []

					craftsMasterStore.setSnackbar({
						value: true,
						text: error.response.data.message,
						type: 'error'
					})
				} finally {
					if (!silent) {
						craftsMasterStore.loading = false
					}
				}
			}
		},
		/**************************************************************************
			N.B. delete actions do not update joc status! must be done by caller
		***************************************************************************/
		async deleteEventById (payload) {
			const { id, silent } = payload

			const craftsMasterStore = useCraftsMasterStore()

			if (id) {
				if (!silent) {
					craftsMasterStore.loading = true
				}

				try {
					const response = await EventDataService.delete(id)
					if (response) {
						const deletedIndex = this.events.findIndex(i => i.EventId === response.data)
						if (deletedIndex !== -1) {
							const deletedEvent = Object.assign({}, this.events[deletedIndex])

							this.events.splice(deletedIndex, 1)

							await addEventNotice({
								event: deletedEvent,
								actionText: 'removed'
							})
						}

						if (!silent) {
							craftsMasterStore.setSnackbar({
								value: true,
								text: `Event ${id}) deleted`
							})
						}
					} else {
						craftsMasterStore.setSnackbar({
							value: true,
							type: 'error',
							text: `Event (${id}) not deleted`
						})
					}
				} catch (error) {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: error.response.data.message
					})
				} finally {
					if (!silent) {
						craftsMasterStore.loading = false
					}
				}
			} else {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: `Event (${id}) not deleted: no EventId`
				})
			}
		},
		async deletePendingEvents (payload) {
			const silent = payload.silent
			delete payload.silent

			const craftsMasterStore = useCraftsMasterStore()

			if (!silent) {
				craftsMasterStore.loading = true
			}

			try {
				const response = await EventDataService.deletePending(payload)

				if (response?.status === 200) {
					await this.getEventsByCompanyId({ silent })
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Events not deleted: Job Order Id (${payload.jobOrderId}), Craftsman Id (${payload.craftsmanId})`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.message
				})
			} finally {
				if (!silent) {
					craftsMasterStore.loading = false
				}
			}
		},
		async deleteJobOrderCraftsmanEvents (payload) {
			const silent = payload.silent
			delete payload.silent
			const craftsMasterStore = useCraftsMasterStore()

			try {
				const response = await EventDataService.deleteJobOrderCraftsman(payload)

				if (response?.status === 200) {
					await this.getEventsByCompanyId({ silent })
				} else {
					craftsMasterStore.setSnackbar({
						value: true,
						type: 'error',
						text: `Events not deleted: Job Order Id (${payload.jobOrderId}), Craftsman Id (${payload.craftsmanId})`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					type: 'error',
					text: error.message
				})
			}
		},
		async deleteEvents (eventIds) {
			const craftsMasterStore = useCraftsMasterStore()
			craftsMasterStore.loading = true

			try {
				const response = await EventDataService.deleteEvents(eventIds)

				if (response && response.data) {
					this.clearEvents(eventIds)

					craftsMasterStore.setSnackbar({
						value: true,
						text: `${response.data.deletedCount} Events deleted.`
					})
				}
			} catch (error) {
				craftsMasterStore.setSnackbar({
					value: true,
					text: error.response.data.message,
					type: 'error'
				})
			} finally {
				craftsMasterStore.loading = false
			}
		},
		async setEventTypeFilter (payload = []) {
			this.eventTypeFilter = payload
		},
		async setEventStatusFilter (payload = []) {
			this.eventStatusFilter = payload
		},
		setShowCurrentJobOrder (value) {
			this.showCurrentJobOrder = value
		},
		setShowCurrentCraftsman (value) {
			this.showCurrentCraftsman = value
		},
		async updateEventAndJocStatus (payload) {
			const silent = payload.silent
			const action = payload.action.toLowerCase()

			const event = Object.assign({}, {
				...payload.event,
				silent
			})

			let jocStatusId = null
			let notices

			switch (action) {
				case 'remove':
					if (event.EventId) {
						await this.deleteEventById({ id: event.EventId, silent })
					} else {
						// remove all pending interviews for given job order id and craftsman id
						await this.deletePendingEvents({
							craftsmanId: event.CraftsmanId,
							jobOrderId: event.JobOrderId,
							typeId: Event.Type.Interview,
							silent
						})
					}

					jocStatusId = Craftsman.getStatusFromEvent(event)

					notices = useNoticeStore().getJobOrderCraftsmanNotices({
						jobOrderId: event.JobOrderId,
						craftsmanId: event.CraftsmanId,
						excludeStatuses: [jocStatusId]
					})

					jocStatusId = notices.length ? notices[0].StatusId : Craftsman.Status.Submitted
					event.EventId = notices.length && notices[0].EventId ? notices[0].EventId : null
					break
				case 'request':
					event.StatusId = event.TypeId === Event.Type.Interview ? Event.Status.Pending : Event.Status.Scheduled

					await this.saveEvent(event)

					jocStatusId = Craftsman.getStatusFromEvent(event)
					break
				case 'schedule':
					event.StatusId = Event.Status.Scheduled

					await this.saveEvent(event)

					await this.deletePendingEvents({
						craftsmanId: event.CraftsmanId,
						jobOrderId: event.JobOrderId,
						typeId: Event.Type.Interview,
						silent
					})
					jocStatusId = Craftsman.getStatusFromEvent(event)
					break
				case 'edit':
					await this.saveEvent(event)

					jocStatusId = Craftsman.getStatusFromEvent(event)
					break
			}

			// finality, update the joc
			if (jocStatusId !== null) {
				await useJobOrderStore().updateJobOrderCraftsmen({
					statusId: jocStatusId,
					eventId: event.EventId,
					jobOrderId: event.JobOrderId,
					craftsmanIds: [event.CraftsmanId],
					silent,
					successMessage: `
						Craftsman status updated (
							${Craftsman.Statuses[jocStatusId].text}
						).
					`
				})
			}
		},

		/****************************************************/
		/**    below actions currently not being called    **/
		/****************************************************/
		async getEventsByJobOrderId (jobOrderId) {
			const jobOrderStore = useJobOrderStore()
			jobOrderId = jobOrderId || jobOrderStore.currentJobOrderId

			if (jobOrderId) {
				const craftsMasterStore = useCraftsMasterStore()
				craftsMasterStore.loading = true

				try {
					const response = await EventDataService.getByJobOrderId(jobOrderId)
					if (response && response.data) {
						this.events = response.data
					} else {
						this.events = []
					}
				} catch (error) {
					this.events = []

					const craftsMasterStore = useCraftsMasterStore()
					craftsMasterStore.setSnackbar({
						value: true,
						text: error.response.data.message,
						type: 'error'
					})
				} finally {
					craftsMasterStore.loading = false
				}
			}
		},
		getEventsByCraftsmanId (craftsmanId) {
			const craftsmanStore = useCraftsmanStore()
			const craftsman = craftsmanStore.craftsmen.find(c => c.CraftsmanId === craftsmanId)
			let events = []
			if (craftsman) {
				events = this.events.filter(e => e.CraftsmanId === craftsmanId)
			}
			return events
		}
	}
})
