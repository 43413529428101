<template>
	<div class="text-truncate fill-height">
		<div :class="['d-flex', 'align-center', isSoloTabActive ? 'solo-tab-active' : 'main-tabs-active']">
			<v-tabs
				:value="value"
				@input="$emit('input', $event.target.value)"
				background-color="transparent"
				:height="tabHeight"
				hide-slider
				active-class="font-weight-black blue-grey lighten-5"
				optional grow
				slider-color="blue-grey"
				color="blue-grey darken-2">
				<v-tab
					v-for="(tab, index) in tabs" :key="index"
					@click="gotoRoute(tab)"
					:href="`#${tab.name}`"
					:class="[isSoloTabActive ? soloTabClass : mainTabClass, { 'd-none': tab.isSolo }]"
					:disabled="tab.disabled">
					<v-badge
						v-if="showNoticeAlert && tab.text === 'Notices'"
						color="red darken-3"
						dot inline>
						<div v-html="tab.text" />
					</v-badge>
					<div v-else v-html="tab.text" />
				</v-tab>
			</v-tabs>
		</div>
		<div class="d-flex align-center grey lighten-4" style="height: 65%">
			<solo-tab v-if="isSoloTabActive" />
		</div>
	</div>
</template>
<script>
import { useCraftsMasterStore } from '@/store/craftsMasterStore'
import { useNoticeStore } from '@/store/noticeStore'
import moment from 'moment'
import { mapState, mapActions } from 'pinia'
import SoloTab from '@/components/SoloTab'

export default {
	components: { SoloTab },
	props: {
		value: String
	},
	data: () => ({
		soloTabClass: ['text-caption', 'text-lowercase'],
		mainTabClass: ['cm-tab-text']
	}),
	computed: {
		...mapState(useNoticeStore, [
			'noticeCenter',
			'noticeCenterVisited'
		]),
		...mapState(useCraftsMasterStore, [
			'tabs',
			'currentTab'
		]),
		isSoloTabActive () {
			return this.currentTab && this.currentTab.isSolo
		},
		showNoticeAlert () {
			let showNoticeAlert = false
			if (this.noticeCenter.length > 0 && !this.noticeCenterVisited) {
				const today = moment()
				const latestNotice = this.noticeCenter[0]
				if (moment(latestNotice.createdAt).isSame(today, 'day')) {
					showNoticeAlert = true
				}
			}
			return showNoticeAlert
		},
		tabHeight () {
			const tabHeight = window.getComputedStyle(document.body).getPropertyValue('--tab-height')
			return this.isSoloTabActive ? '100%' : tabHeight
		}
	},
	methods: {
		...mapActions(useNoticeStore, ['setNoticeCenterVisited']),
		// "to" attribute on v-tab causes "missing param for named route" console warning
		gotoRoute (tab) {
			if (tab.text === 'Notices') {
				this.setNoticeCenterVisited(true)
			}
			this.$router.push({
				name: tab.name,
				params: tab.params
			}).catch(error => {
				if (error.name != 'NavigationDuplicated') {
					throw error
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.solo-tab-active {
	height: 35%;
	padding: 0 10%;
	transition: all 1s;
}
.main-tabs-active {
	height: 100%;
	padding: 0;
	transition: all 1s;
}
</style>
