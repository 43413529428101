import { defineStore } from 'pinia'
import CraftsMaster from '@/models/craftsMaster'
import { useNoticeStore } from '@/store/noticeStore'
import Vue from 'vue'

export const useCraftsMasterStore = defineStore('craftsMaster', {
	state: () => ({
		drawerComponent: null,
		loading: false,
		loadingText: '',
		snackbar: {
			value: false,
			text: '',
			color: 'green darken-2',
			timeout: 2000
		},
		testDataLimits: [],
		tabs: [],
		leftMenuBackText: '',
		fileUploadInProgress: false,
		currentAnchor: null,
		currentTab: null,
		route: null,
		routeFrom: null,
		textSearchItems: [],
		errorMessages: [],
		fieldFilter: []
	}),
	actions: {
		async setTestDataLimits (testDataLimits) {
			if (!testDataLimits) {
				testDataLimits = localStorage.getItem(CraftsMaster.TestDataLimitsKey)
				if (testDataLimits) {
					testDataLimits = JSON.parse(testDataLimits)
				} else {
					testDataLimits = JSON.parse(JSON.stringify(CraftsMaster.DefaultTestDataLimits))
					localStorage.setItem(CraftsMaster.TestDataLimitsKey, JSON.stringify(testDataLimits))
				}
			} else {
				localStorage.setItem(CraftsMaster.TestDataLimitsKey, JSON.stringify(testDataLimits))
			}
			this.testDataLimits = JSON.parse(JSON.stringify(testDataLimits))
		},
		setDrawerComponent (component) {
			this.drawerComponent = component ? Object.assign({}, component) : null
		},
		updateDrawerComponent (payload) {
			this.drawerComponent = Object.assign(this.drawerComponent, payload)
		},
		setLoading (loading, loadingText) {
			this.loading = loading
			if (loadingText) {
				this.loadingText = loadingText
			}
			if (!loading) {
				this.loadingText = ''
			}
		},
		setSnackbar (snackbar) {
			if (snackbar) {
				let color = snackbar.color
				if (!color) {
					switch (snackbar.type) {
						case 'error':
							color = 'red darken-2'
							break
						case 'info':
							color = 'blue darken-2'
							break
						default:
							color = 'green darken-2'
							break
					}
				}
				this.snackbar.value = snackbar.value || false
				this.snackbar.type = snackbar.type || ''
				this.snackbar.text = snackbar.text || ''
				this.snackbar.maxWidth = snackbar.maxWidth || ''
				this.snackbar.multiline = snackbar.multiline || false
				this.snackbar.vertical = snackbar.vertical || false
				this.snackbar.color = color
				this.snackbar.timeout = snackbar.timeout || (snackbar.type === 'error' ? -1 : 1800)

				if (this.snackbar.type === 'error') {
					let Notes = '<div class="font-weight-bold red--text">Error</div>'
					Notes += `<div>${this.snackbar.text}</div>`
					useNoticeStore().addNotice({
						Notes,
						Level: 2
					})
				}
			}
		},
		showSnackbar (show) {
			this.snackbar.value = show
		},
		setTabs (tabs, tab) {
			this.tabs = JSON.parse(JSON.stringify(tabs))
			if (tab) {
				this.currentTab = { ...tab }
				document.documentElement.style.setProperty('--portal-tab-color', tab.hexColor || '#607d8b')
			}
		},
		setLeftMenuBackText (text) {
			this.leftMenuBackText = text
		},
		setFileUploadInProgress (val) {
			this.fileUploadInProgress = val
		},
		setCurrentAnchor (val) {
			this.currentAnchor = val
		},
		setRoute (val) {
			this.route = val
		},
		setRouteFrom (val) {
			this.routeFrom = val
		},
		setTextSearchItems (val) {
			this.textSearchItems = val
		},
		setErrorMessages (val) {
			this.errorMessages = val
		},
		clearErrorMessages () {
			this.errorMessages.splice(0)
		},
		setFieldFilter (val) {
			const fieldFilterIndex = this.fieldFilter.findIndex(f => f.source === val.source)
			if (fieldFilterIndex !== -1) {
				Vue.set(this.fieldFilter, fieldFilterIndex, val)
			} else {
				this.fieldFilter.push(val)
			}
		}
	}
})
